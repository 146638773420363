// 系统编程语言
export const languageType = [
  {
    value: 0,
    label: 'C'
  },
  {
    value: 1,
    label: 'C++'
  },
  {
    value: 2,
    label: 'Pascal'
  },
  {
    value: 3,
    label: 'Java'
  },
  {
    value: 4,
    label: 'Ruby'
  },
  {
    value: 5,
    label: 'Bash'
  },
  {
    value: 6,
    label: 'Python'
  },
  {
    value: 7,
    label: 'php'
  },
  {
    value: 8,
    label: 'perl'
  },
  {
    value: 9,
    label: 'c#'
  },
  {
    value: 10,
    label: 'objectiveC'
  },
  {
    value: 11,
    label: 'freeBasic'
  },
  {
    value: 12,
    label: 'scheme'
  },
  {
    value: 13,
    label: 'Clang'
  },
  {
    value: 14,
    label: 'Clang++'
  },
  {
    value: 15,
    label: 'Lua'
  },
  {
    value: 16,
    label: 'JavaScript'
  },
  {
    value: 17,
    label: 'Go'
  }
]

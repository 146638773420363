<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
    top="7vh"
    @open="openDialog"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>代码在线调试</span>
    </div>
    <!-- aceEditorValue：{{aceEditorValue}}
    <hr>
    currentCode：{{currentCode}}
    <hr> -->
    <el-card>
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <el-form-item label="输入">
          <el-input
            type="textarea"
            autosize
            v-model="formLabelAlign.input">
          </el-input>
        </el-form-item>
        <el-form-item label="输出">
          <el-input
            type="textarea"
            disabled
            autosize
            v-model="formLabelAlign.output">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onDebug" :loading="isLoading">在线调试</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </el-dialog>
</template>

<script>
import Qs from "qs"
import { languageType } from '@/utils/language'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    currentCode: {
      default: '',
      type: String
    },
    aceEditorValue: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      formLabelAlign: {
        input: '',
        output: ''
      },
      language: null,
      languageList: languageType,
      isLoading: false
    }
  },
  methods: {
    openDialog () {
      this.formLabelAlign = {
        input: '',
        output: ''
      }
      this.languageList.forEach((ele, idx) => {
        if (ele.label === this.currentCode) {
          this.language = ele.value
        }
      })
    },
    // 关闭
    close () {
      this.$emit('close')
    },
    onDebug () {
      this.isLoading = true
      fPost('/student/other/runCode', {
        language: String(this.language),
        code: this.aceEditorValue,
        stdIn: this.formLabelAlign.input
      }).then(res => {
        if (res.state == "success") {
          this.isLoading = false
          if (res.body.result >= 5 && res.body.result <=11) {
            this.formLabelAlign.output = res.body.compileInfo
          } else if (res.body.result === 13) {
            this.formLabelAlign.output = res.body.runtimeInfo
          }
        } else {
          this.isLoading = false
          $msg(res.errMsg, 2);
        }
      })
      .catch(err => {
        this.isLoading = false
        $msg('接口请求失败', 1)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";

/deep/ .el-dialog {
  background: #ffffff;
  border: #ffffff 0px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>

<template>
    <iframe
      id="iframe"
      :src="taskInfo.sourceContent"
      frameborder="1"
      allowfullscreen="true"
      width="100%"
      height="99%"
      seamless
      sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
    ></iframe>
</template>

<script>
import { showLoading , hideLoading } from '@/utils/loading'

export default {
  props: {
    taskInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {}
  },
  mounted () {
    var iframe = document.getElementById('iframe')
    showLoading()
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', function () {
        hideLoading()
      })
    } else {
      iframe.onload = function () {
        hideLoading()
      }
    }
  },
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        { staticStyle: { "background-color": "#212121" } },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-col", {
                staticStyle: { height: "60px" },
                attrs: { span: 6 },
              }),
              _c(
                "el-col",
                { staticStyle: { "line-height": "60px" }, attrs: { span: 3 } },
                [
                  _c(
                    "div",
                    { staticClass: "parenttwo" },
                    [
                      _c("el-button", {
                        staticClass: "btnbg",
                        attrs: { icon: "el-icon-arrow-left", type: "text" },
                        on: { click: _vm.gotoPreQuestion },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-col", { staticClass: "btnbgmiddle", attrs: { span: 6 } }, [
                _c("div", { staticClass: "parentone" }, [
                  _c("span", { staticClass: "titlequestion questionPanel" }, [
                    _vm._v(_vm._s(_vm.titleText)),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-dropdown",
                        { on: { command: _vm.handleCommand } },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("span", {
                              staticClass: "el-icon-arrow-down",
                              staticStyle: {
                                top: "0px",
                                left: "0px",
                                "font-size": "16px",
                                color: "#FFFFFF",
                              },
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "questionlist",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(_vm.questionList, function (item, index) {
                              return _c(
                                "el-dropdown-item",
                                { key: index, attrs: { command: item } },
                                [
                                  _vm._v(
                                    _vm._s(index + 1) +
                                      ".  " +
                                      _vm._s(item.problemName)
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "el-col",
                { staticStyle: { "line-height": "60px" }, attrs: { span: 3 } },
                [
                  _c(
                    "div",
                    { staticClass: "parenttwo" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btnbg",
                          attrs: { type: "text" },
                          on: { click: _vm.gotoAfterQuestion },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { "line-height": "60px" }, attrs: { span: 6 } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-right": "120px",
                        "background-color": "#f5c319",
                        color: "#000000",
                        "border-radius": "20px",
                        width: "120px",
                        height: "35px",
                        "line-height": "35px",
                        "margin-top": "13px",
                        float: "right",
                        "text-align": "center",
                        "font-size": "14px",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.gotorecord()
                        },
                      },
                    },
                    [_vm._v("提交记录")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticStyle: { width: "100%", height: "100%", overflow: "hidden" } },
        [
          _c("div", { staticClass: "parentall", attrs: { id: "box" } }, [
            _c(
              "div",
              {
                staticClass: "grid-content bg-purple leftcls",
                staticStyle: { "overflow-y": "auto", "overflow-x": "hidden" },
                style: _vm.height,
                attrs: { id: "top" },
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "parentitem" }, [
                      _c("div", { staticClass: "titlequestion" }, [
                        _vm._v(_vm._s(_vm.titleText)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "decription",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _vm._v(
                            " 时间限制：" + _vm._s(_vm.timeLimit) + "秒        "
                          ),
                          _c("span", [
                            _vm._v(
                              "内存限制：" + _vm._s(_vm.memoryLimit) + "M"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("el-col", { staticClass: "parentitem" }, [
                      _c(
                        "div",
                        {
                          staticClass: "titlequestion",
                          staticStyle: { display: "flex" },
                        },
                        [_vm._v("题目描述")]
                      ),
                      _c("div", {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { id: "description" },
                      }),
                    ]),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inputshow,
                            expression: "inputshow",
                          },
                        ],
                        staticClass: "parentitem",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "titlequestion",
                            staticStyle: { display: "flex" },
                          },
                          [_vm._v("输入描述")]
                        ),
                        _c("div", {
                          staticClass: "decription",
                          staticStyle: { "margin-top": "20px" },
                          attrs: { id: "input" },
                        }),
                      ]
                    ),
                    _c("el-col", { staticClass: "parentitem" }, [
                      _c(
                        "div",
                        {
                          staticClass: "titlequestion",
                          staticStyle: { display: "flex" },
                        },
                        [_vm._v("输出描述")]
                      ),
                      _c("div", {
                        staticClass: "decription",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { id: "output" },
                      }),
                    ]),
                    _c("el-col", { staticClass: "parentitem" }, [
                      _c("div", { staticClass: "titlequestion" }, [
                        _vm._v("样例"),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sampleinputshow,
                              expression: "sampleinputshow",
                            },
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "decription",
                              staticStyle: { "margin-top": "20px" },
                            },
                            [_vm._v("输入")]
                          ),
                          _c("div", {
                            staticClass: "entercontent",
                            attrs: { id: "sample_input" },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "decription",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [_vm._v("输出")]
                      ),
                      _c("div", {
                        staticClass: "entercontent",
                        attrs: { id: "sample_output" },
                      }),
                    ]),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tipshow,
                            expression: "tipshow",
                          },
                        ],
                        staticClass: "parentitem",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "titlequestion",
                            staticStyle: { display: "flex" },
                          },
                          [_vm._v("提示")]
                        ),
                        _c("div", {
                          staticClass: "decription",
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-bottom": "80px",
                          },
                          attrs: { id: "hint" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", {
              staticClass: "line",
              attrs: { id: "line" },
              on: {
                mousedown: function ($event) {
                  return _vm.lineMouse($event)
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "rightParent",
                staticStyle: { height: "100%", overflow: "hidden" },
                attrs: { id: "bottom" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showace,
                        expression: "!showace",
                      },
                    ],
                    staticClass: "rightitem",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.gotoresultdetail },
                      },
                      [
                        _c(
                          "el-row",
                          { staticClass: "rightcontent" },
                          [
                            _c("el-col", { staticClass: "titlequestion" }, [
                              _vm._v("我的提交"),
                            ]),
                            _c(
                              "el-col",
                              { staticClass: "secondtitle colcls" },
                              [
                                _c("span", [_vm._v("提交时间： ")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("dateformat")(_vm.submittime))
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "secondtitle colcls" },
                              [
                                _c("span", [_vm._v("提交结果： ")]),
                                _c("span", {
                                  style:
                                    "color" +
                                    ":" +
                                    _vm.getStateColor(_vm.resultNumber),
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.getSubType(_vm.resultNumber)
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "decription showmessage" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-yongshi ico-time",
                                    }),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "用时",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pointer",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.onedata) + "ms")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "15px" } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-qian ico-bulk",
                                    }),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "内存",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pointer",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.twodata) + "KB")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "15px" } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-storage-capacity-copy",
                                    }),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "代码长度",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pointer",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.threedata) + "B")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-top": "60px",
                              "margin-left": "30px",
                            },
                            attrs: { type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.editorCode()
                              },
                            },
                          },
                          [_vm._v("编辑代码")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showace,
                        expression: "showace",
                      },
                    ],
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      background: "#181E22",
                    },
                    attrs: { id: "boxtwo" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "edittop" },
                      [
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.setmodemessage } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  background: "#516169",
                                  border: "none",
                                  "margin-left": "10px",
                                },
                                attrs: { type: "primary" },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.currentCode) + " "),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              {
                                staticStyle: {
                                  width: "8%",
                                  "text-align": "center",
                                },
                                attrs: { slot: "dropdown" },
                                slot: "dropdown",
                              },
                              _vm._l(_vm.modeArray, function (item, index) {
                                return _c(
                                  "el-dropdown-item",
                                  { key: index, attrs: { command: item } },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  background: "#516169",
                                  border: "none",
                                  "margin-right": "15px",
                                },
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeCode()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-share",
                                  staticStyle: { "margin-right": "10px" },
                                }),
                                _vm._v("收起代码编辑区 "),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      ref: "ace",
                      staticClass: "ace-editor",
                      style: _vm.middleheight,
                      attrs: { id: "toptwo" },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: { cursor: "s-resize" },
                        attrs: { id: "linetwo" },
                        on: {
                          mousedown: function ($event) {
                            return _vm.topMouse($event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click",
                                value: 5000,
                                expression: "5000",
                              },
                            ],
                            staticStyle: { margin: "10px" },
                            attrs: { type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.submitCode()
                              },
                            },
                          },
                          [_vm._v("提交代码")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showresult,
                            expression: "showresult",
                          },
                        ],
                        staticStyle: { "background-color": "#3d444c" },
                        style: _vm.footerheight,
                        attrs: { id: "bottomtwo" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.getresult,
                                expression: "!getresult",
                              },
                            ],
                            attrs: { id: "tipsone" },
                          },
                          [
                            _c("div", { staticClass: "tipclass" }, [
                              _vm._v("您的代码已经保存"),
                            ]),
                            _c("div", { staticClass: "tipclass" }, [
                              _vm._v("正在查询结果..."),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.getresult,
                                expression: "getresult",
                              },
                            ],
                            attrs: { id: "tipstwo" },
                          },
                          [
                            _c("div", { staticClass: "tipclass" }, [
                              _c("span", {
                                style:
                                  "color" +
                                  ":" +
                                  _vm.getStateColor(_vm.currentResultNumber),
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.getSubType(_vm.currentResultNumber)
                                  ),
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "217px",
                                  "overflow-y": "auto",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "tipclass",
                                  attrs: { id: "bianyiText" },
                                }),
                                _c("div", {
                                  staticClass: "tipclass",
                                  attrs: { id: "runtimeText" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "show-close": false,
            width: "30%",
            top: "7vh",
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-error",
            staticStyle: {
              float: "right",
              "font-size": "25px",
              color: "#FFFFFF",
              cursor: "pointer",
            },
            on: {
              click: function ($event) {
                _vm.dialogVisible = false
              },
            },
          }),
          _c("img", {
            staticClass: "animated infinite pulse",
            attrs: { src: require("../../../../assets/images/success.png") },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
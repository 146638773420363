<template>
    <el-container>
        <el-header style="background-color: #212121;line-height: 60px;" class="header">
            <span style="color: #fff">{{taskInfo.title}}</span>
            <div class="header-left">
                <div style="margin-right: 35px;">
                    <el-button type="text" v-if="videoEnable" @click="previewVideo"><i class="el-icon-video-camera"
                                                                                       style="display: inline-block;margin-right: 10px;"></i>查看题解视频
                    </el-button>
                </div>
                <div class="parenttwo">
                    <el-button
                            @click="gotoPreQuestion"
                            class="btnbg"
                            icon="el-icon-arrow-left"
                            type="text"
                    ></el-button>
                </div>
                <div class="parentone">
          <span
                  class="titlequestion questionPanel"
          >{{ titleText }}</span>
                    <div>
                        <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                <span
                        class="el-icon-arrow-down"
                        style="top: 0px; left: 0px;font-size: 16px;color: #FFFFFF;"
                ></span>
              </span>
                            <el-dropdown-menu class="questionlist" slot="dropdown">
                                <el-dropdown-item
                                        :command="item"
                                        :key="index"
                                        v-for="(item, index) in questionList"
                                >{{ index + 1 }}.&nbsp;&nbsp;{{ item.problemName }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div class="parenttwo">
                    <el-button @click="gotoAfterQuestion" class="btnbg" type="text">
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>
                <div class="parenttwo" style="margin-left: 20px;">
                    <el-button @click="gotorecord" class="btnbg" type="text">
                        <i class="el-icon-document el-document"></i>我的提交
                    </el-button>
                </div>
            </div>
            <!-- <div style="flex: 1"></div> -->
            <div v-if="taskID">
                <template v-if="!taskInfo.finishTime">
                    <el-popover class="footer-condition" placement="top-end" width="150" trigger="hover">
                        <el-row class="condition-con">
                            <p>满足以下条件：</p>
                            <p>完成所有已开放的题目</p>
                        </el-row>
                        <span slot="reference">
              <span class="el-icon-question"></span>任务完成条件
            </span>
                    </el-popover>
                    <template v-if="taskInfo.type === '编程'">
                        <el-button
                                type="primary"
                                size="small"
                                round
                                @click="taskFinish"
                                class="footer-btn"
                        >完成任务
                        </el-button>
                        <!-- :disabled="!isFinish" -->
                    </template>
                </template>
                <template v-else>
                    <el-button
                            type="primary"
                            size="small"
                            round
                            class="footer-btn"
                            @click="nextTask()"
                    >已完成、进入下个任务
                    </el-button>
                </template>
            </div>
        </el-header>
        <!-- main -->
        <el-main style="overflow: hidden;">
            <div class="components-container">
                <!--         taskInfo：{{taskInfo}}-->
                <split-pane split="vertical" :min-percent='25' :default-percent='40'>
                    <template slot="paneL">
                        <div class="left-container grid-content bg-purple">
                            <el-row>
                                <el-col class="parentitem">
                                    <div class="titlequestion">{{ titleText }}</div>
                                    <div class="decription" style="margin-top: 20px;">
                                        时间限制：{{
                                        timeLimit
                                        }}秒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>内存限制：{{ memoryLimit }}M</span>
                                    </div>
                                </el-col>
                                <el-col class="parentitem">
                                    <div class="titlequestion" style="display: flex">题目描述</div>
                                    <div v-html="dataObejcet.description" class="decription"
                                         style="margin-top: 20px;"></div>
                                </el-col>
                                <el-col class="parentitem" v-show="dataObejcet.input">
                                    <div class="titlequestion" style="display: flex">输入描述</div>
                                    <div v-html="dataObejcet.input" class="decription" style="margin-top: 20px;"></div>
                                    <!-- <div class="decription" id="input" style="margin-top: 20px;"></div> -->
                                </el-col>
                                <el-col class="parentitem">
                                    <div class="titlequestion" style="display: flex">输出描述</div>
                                    <div v-html="dataObejcet.output" class="decription" style="margin-top: 20px;"></div>
                                    <!-- <div class="decription" id="output" style="margin-top: 20px;"></div> -->
                                </el-col>
                                <el-col class="parentitem">
                                    <div class="titlequestion">样例</div>
                                    <div v-show="dataObejcet.sample_input">
                                        <div class="decription" style="margin-top: 20px;">输入</div>
                                        <!-- <div class="entercontent" id="sample_input"></div> -->
                                        <pre v-html="dataObejcet.sample_input" class="entercontent"></pre>
                                    </div>
                                    <div class="decription" style="margin-top: 20px;">输出</div>
                                    <!-- <div class="entercontent" id="sample_output"></div> -->
                                    <pre v-html="dataObejcet.sample_output" class="entercontent"></pre>
                                </el-col>
                                <el-col class="parentitem" v-show="Boolean(dataObejcet.hint)">
                                    <div class="titlequestion" style="display: flex">提示</div>
                                    <!-- dataObejcet.hint：{{dataObejcet.hint}} -->
                                    <div v-html="dataObejcet.hint" class="decription" style="margin-top: 20px;"></div>
                                    <!-- <div class="decription" id="hint" style="margin-top: 20px;margin-bottom: 80px;"></div> -->
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                    <template slot="paneR">
                        <split-pane split="horizontal" @resize="resize" :min-percent='showace ? 22 : 0'
                                    :default-percent='showace ? 78 : 100'>
                            <template slot="paneL">
                                <div class="top-container">
                                    <div class="rightitem" v-show="!showace">
                                        <div @click="gotoresultdetail" style="cursor:pointer;">
                                            <el-row class="rightcontent">
                                                <el-col class="titlequestion">我的提交</el-col>
                                                <el-col class="secondtitle colcls">
                                                    <span>提交时间：&nbsp;</span>
                                                    <span>{{submittime | dateformat}}</span>
                                                </el-col>
                                                <el-col class="secondtitle colcls">
                                                    <span>提交结果：&nbsp;</span>
                                                    <span :style="'color' + ':' + getStateColor(resultNumber)"
                                                          v-text="getSubType(resultNumber)"></span>
                                                </el-col>
                                                <el-col class="decription showmessage">
                                                    <div>
                                                        <i class="iconfont icon-yongshi ico-time"></i>
                                                        <el-tooltip content="用时" placement="top">
                                                            <span style="margin-left: 5px;" class="pointer">{{ onedata }}ms</span>
                                                        </el-tooltip>
                                                    </div>
                                                    <div style="margin-left: 15px;">
                                                        <i class="iconfont icon-qian ico-bulk"></i>
                                                        <el-tooltip content="内存" placement="top">
                                                            <span style="margin-left: 5px;" class="pointer">{{ twodata }}KB</span>
                                                        </el-tooltip>
                                                    </div>
                                                    <div style="margin-left: 15px;">
                                                        <i class="iconfont icon-storage-capacity-copy"></i>
                                                        <el-tooltip content="代码长度" placement="top">
                                                            <span style="margin-left: 5px;" class="pointer">{{ threedata }}B</span>
                                                        </el-tooltip>

                                                        <el-tooltip content="编程语言" placement="top">
                                                            <span style="margin-left: 15px;"
                                                                  v-text="getLanText(this.language)"
                                                                  class="pointer"></span>
                                                        </el-tooltip>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <el-col>
                                            <el-button
                                                    style="margin-top: 60px;margin-left: 30px"
                                                    type="warning"
                                                    v-on:click="editorCode()"
                                            >编辑代码
                                            </el-button>
                                        </el-col>
                                    </div>
                                    <!--  -->
                                    <div
                                            id="boxtwo"
                                            style="display: flex;height: 100%;flex-direction: column;background: #181E22;"
                                            v-show="showace"
                                    >
                                        <div class="edittop">
                                            <el-dropdown @command="setmodemessage">
                                                <el-button
                                                        style="background: #516169;border:none; margin-left: 10px;"
                                                        type="primary"
                                                >
                                                    {{ currentCode }}
                                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown" style="width: 8%;text-align: center">
                                                    <el-dropdown-item
                                                            :command="item"
                                                            :key="index"
                                                            v-for="(item, index) in topicModeArray"
                                                    >{{ item.name }}
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                            <div>
                                                <el-button
                                                        style="background: #516169;border:none; margin-right: 15px;"
                                                        type="primary"
                                                        v-on:click="closeCode()"
                                                >
                                                    <i class="el-icon-share" style="margin-right: 10px;"></i>收起代码编辑区
                                                </el-button>
                                            </div>
                                        </div>
                                        <div class="ace-editor" id="toptwo" ref="ace"></div>
                                    </div>
                                </div>
                            </template>
                            <template slot="paneR" v-if="showace">
                                <div class="bottom-container">
                                    <div style="background-color: rgb(24, 30, 34);">
                                        <el-button
                                                style="margin: 10px;"
                                                type="info"
                                                v-on:click="debug()"
                                                v-prevent-re-click="5000"
                                        >在线调试
                                        </el-button>
                                        <el-button
                                                style="margin: 10px;"
                                                type="warning"
                                                v-on:click="submitCode()"
                                                v-prevent-re-click="5000"
                                        >提交代码
                                        </el-button>
                                    </div>
                                    <div
                                            id="bottomtwo"
                                            style="background-color: #3d444c;height: 100%"
                                            v-show="showresult"
                                    >
                                        <div id="tipsone" v-show="!getresult">
                                            <div class="tipclass">您的代码已经保存</div>
                                            <div class="tipclass">正在查询结果...</div>
                                        </div>
                                        <div id="tipstwo" v-show="getresult">
                                            <div class="tipclass">
                        <span
                                :style="'color' + ':' + getStateColor(currentResultNumber)"
                                v-text="getSubType(currentResultNumber)"
                                id="compileText"
                        ></span>
                                            </div>
                                            <div style="height: 100%">
                                                <div class="tipclass" id="bianyiText"></div>
                                                <div class="tipclass" id="runtimeText"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </split-pane>
                    </template>
                </split-pane>
            </div>
        </el-main>

        <!-- 在线调试代码 dialog -->
        <code-debugging :show="debuggingDialog" :aceEditorValue="aceEditorValue" :currentCode="currentCode"
                        @close="debuggingDialog=false"></code-debugging>

        <!-- 代码正确提示 -->
        <!-- <code-success :show="successDialog" @close="successDialog=false"></code-success> -->
        <!-- 题解视频 -->
        <!-- <dialog-video :id="videoUrl" :videoVisible="videoVisible" :videoSrc="videoUrl" @videoClose="videoClose"
                      :taskId="$route.query.id"></dialog-video> -->

    </el-container>
</template>
<script>
    import splitPane from 'vue-splitpane'
    import Prism from "prismjs"; //引入插件
    import globalClient from '@/api/globalVariable'
    import ace from "ace-builds"
    import getSubType from "@/mixins/status"
    import getStateColor from "@/mixins/status"
    import codeDebugging from './debugging'
    // import codeSuccess from '../components/public/codeSuccess'
    import "ace-builds/src-noconflict/snippets/javascript"
    import "ace-builds/src-noconflict/snippets/html"
    import "ace-builds/src-noconflict/snippets/java"
    import "ace-builds/src-noconflict/snippets/c_cpp"
    import "ace-builds/src-noconflict/snippets/pascal"
    import "ace-builds/src-noconflict/snippets/ruby"
    import "ace-builds/src-noconflict/snippets/batchfile"
    import "ace-builds/src-noconflict/snippets/python"
    import "ace-builds/src-noconflict/snippets/php"
    import "ace-builds/src-noconflict/snippets/perl"
    import "ace-builds/src-noconflict/snippets/objectivec"
    import "ace-builds/src-noconflict/snippets/scheme"
    import "ace-builds/src-noconflict/snippets/clojure"
    import "ace-builds/src-noconflict/snippets/lua"
    import "ace-builds/src-noconflict/snippets/javascript"
    import "ace-builds/src-noconflict/snippets/golang"
    import "ace-builds/src-noconflict/snippets/gobstones"
    import "ace-builds/src-noconflict/snippets/django"
    import "ace-builds/webpack-resolver"
    import "ace-builds/src-noconflict/ext-language_tools"
    import "ace-builds/src-noconflict/theme-monokai"
    import "ace-builds/src-noconflict/theme-clouds_midnight"
    import "ace-builds/src-noconflict/mode-javascript"
    // import dialogVideo from '@/components/public/dialogVideo'

    import {formatTate} from '@/utils/formatDate'

    const modeArray = [
        {
            name: "C",
            path: "ace/mode/c_cpp",
            value: '0'
        },
        {
            name: "C++",
            path: "ace/mode/c_cpp",
            value: '1'
        },
        {
            name: "JavaScript",
            path: "ace/mode/javascript",
            value: '16'
        },
        {
            name: "Java",
            path: "ace/mode/java",
            value: '3'
        },
        {
            name: "Python",
            path: "ace/mode/python",
            value: '6'
        },
        {
            name: "php",
            path: "ace/mode/php",
            value: '7'
        },
        {
            name: "Pascal",
            path: "ace/mode/pascal",
            value: '2'
        },
        {
            name: "Ruby",
            path: "ace/mode/ruby",
            value: '4'
        },
        {
            name: "freeBasic",
            path: "ace/mode/batchfile",
            value: '11'
        },
        {
            name: "perl",
            path: "ace/mode/perl",
            value: '8'
        },
        {
            name: "c#",
            path: "ace/mode/c_cpp",
            value: '9'
        },
        {
            name: "objectiveC",
            path: "ace/mode/objectivec",
            value: '10'
        },
        {
            name: "scheme",
            path: "ace/mode/scheme",
            value: '12'
        },
        {
            name: "Lua",
            path: "ace/mode/lua",
            value: '15'
        },
        {
            name: "Go",
            path: "ace/mode/golang",
            value: '17'
        }
    ]
    export default {
        mixins: [getSubType, getStateColor],
        components: {splitPane, codeDebugging
        },
        props: {
            value: String,
            contest_id: {
                default: "",
                type: String
            },
            taskInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
            taskID: {
                default: "",
                type: String
            }
        },
        data() {
            return {
                // dialogVisible: false,
                canInterval: true,
                number: 0,
                aceEditor: null,
                toggle: false,
                wrap: true,
                showace: true,
                showresult: false,
                currentCode: "",
                themePath: "ace/theme/monokai",
                modePath: "ace/mode/c_cpp",
                modeArray: modeArray,
                currentQuestionNum: 0,
                currentResultNumber: 2,
                currentLanguage: 1,
                questionList: [],
                titleText: "",
                timeLimit: "",
                memoryLimit: "",
                input: "",
                output: "",
                resultNumber: 0,
                submittime: "",
                onedata: "",
                twodata: "",
                threedata: "",
                language: "",
                submitFlag: false,
                questionObject: {},
                getresult: false,
                currentSolutionId: 0,
                beginTime: 0,
                endTime: 0,
                problem_id: 0,
                goonNumber: 0,
                interval: 0,
                lesson_id: "",
                sampleinputshow: true,
                inputshow: true,
                tipshow: true,
                isSubmitCode: false,
                isFinish: false, // 记录是否完成所有已开发的编程题
                debuggingDialog: false,
                aceEditorValue: null,
                topicModeArray: [],
                successDialog: false,
                questionListData: [],
                dataObejcet: {},
                videoEnable: false, // 是否开发题解视频
                isVideo: false, // 当前题目是否有题解视频
                videoUrl: '',
                videoVisible: false,
            }
        },
        created() {
            this.lesson_id = this.$route.query.lessonId
            this.getQuestionList();
            this.beginTime = formatTate(new Date())

            this.getContextInfo()
        },
        mounted() {
            this.aceEditor = ace.edit(this.$refs.ace, {
                fontSize: 20,
                value: this.value ? this.value : "",
                theme: this.themePath,
                mode: this.modePath,
                wrap: this.wrap,
                tabSize: 4
            });
            ace.require("ace/ext/language_tools");
            // 激活自动提示
            this.aceEditor.setOptions({
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            });
            this.aceEditor.getSession().on("change", this.change);
        },

        methods: {
            setCodeToparent() {
                let object = {
                    code: "当前题目：" + this.titleText + '\n\n' + this.aceEditor.getValue()
                }
                this.$emit('getSplitpaneCode', object)
            },
            setLocalCode() {
                let that = this
                let codeObject = {
                    type: 'sendCurrentCode',
                    code: that.aceEditor.getValue()
                }
                globalClient.globalChannel.sendMessage({text: JSON.stringify(codeObject)}).then(() => {
                    /* 频道消息发送成功的处理逻辑 */
                    console.log('sendMesssage=======>success')
                }).catch(error => {
                    console.log('sendMesssage=======>fail')
                    /* 频道消息发送失败的处理逻辑 */
                });
            },
            succe() {
                this.successDialog = true
            },


            getProblemUserById(id) {
                fGet("/student/question/getProblemUserById", {
                    problemId: id,
                    contestId:this.contest_id
                })
                    .then(res => {
                        if (res.state === "success") {
                            this.videoEnable = res.body.defunctVideo === 'Y'?false:true
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err1', err)
                        $msg(err + "请求失败", 1);
                    });
            },
            getContextInfo() {
                fGet("/student/question/getContextInfo", {
                    contest_id: this.contest_id
                })
                    .then(res => {
                        if (res.state === "success") {
                            modeArray.forEach((ele, idx) => {
                                res.body.langmask.split(',').forEach((item) => {
                                    if (item === ele.value) {
                                        this.currentCode = ele.name
                                        this.topicModeArray.push(ele)
                                    }
                                })
                            })
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err1', err)
                        $msg(err + "请求失败", 1);
                    });
            },
            debug() {
                if (this.aceEditor.getValue() === "") {
                    $msg("提交代码不能为空", 2);
                    return false;
                } else {
                    this.aceEditorValue = this.aceEditor.getValue()
                    this.debuggingDialog = true
                }
            },
            // 手动点击完成任务
            taskFinish() {
                this.$emit('getTaskFinish')
            },
            nextTask() {
                this.$emit('nextTask')
            },
            change() {
                this.$emit("input", this.aceEditor.getSession().getValue());
            },
            resize() {
                let toptwo = document.getElementById('toptwo').clientHeight
                let ace_content = document.getElementsByClassName('ace_content')[0].style.height
                // console.log('toptwo', toptwo)
                document.getElementsByClassName('ace_content')[0].style.height = toptwo + 'px'
                // console.log('ace_content', ace_content)
            },

            // 获取题库的问题
            getQuestionList() {
                fGet("/student/question/getQuestionList", {
                    contest_id: this.contest_id,
                    startTime: this.taskInfo.minHistoryTime
                })
                    .then(res => {
                        if (res.state === "success") {
                            this.questionListData = res.body
                            if (res.body.length == 0) {
                                $msg("题目未开放，请联系老师(contest_id=" + this.contest_id + ")", 2);
                                return false;
                            }
                            this.currentQuestionNum = 0;
                            this.questionList = res.body;
                            this.questionObject = this.questionList[0];
                            this.getQuestionDetailById(this.questionList[0]);
                            this.getProblemUserById(this.questionList[0].problem_id)
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err1', err)
                        $msg(err + "请求失败", 1);
                    });
            },

            // 获取题目详情
            getQuestionDetailById(thembQuestionOj) {
                this.showresult = false
                this.getresult = false
                this.problem_id = thembQuestionOj.problem_id
                if (thembQuestionOj.solutionBean != null && thembQuestionOj.solutionBean != "null") {
                    this.resultNumber = thembQuestionOj.solutionBean.result;
                    this.submittime = thembQuestionOj.solutionBean.in_date;
                    this.onedata = thembQuestionOj.solutionBean.time;
                    this.twodata = thembQuestionOj.solutionBean.memory;
                    this.threedata = thembQuestionOj.solutionBean.code_length;
                    this.language = thembQuestionOj.solutionBean.language
                } else {
                    this.resultNumber = -1;
                    this.submittime = '暂无提交时间'
                    this.onedata = 0
                    this.twodata = 0
                    this.threedata = 0
                    this.language = null
                }

                this.showace = !thembQuestionOj._submit;
                this.submitFlag = thembQuestionOj._submit;

                fGet("/student/question/getQuestionById", {
                    problem_id: thembQuestionOj.problem_id
                })
                    .then(res => {
                        if (res.state == "success") {
                            var dataObejcet = res.body[0];
                            this.dataObejcet = res.body[0];
                            this.titleText = dataObejcet.title;
                            this.timeLimit = dataObejcet.time_limit;
                            this.memoryLimit = dataObejcet.memory_limit;
                            // document.getElementById(
                            //   "description"
                            // ).innerHTML = dataObejcet.description.replace(/\n/g, "<br/>");
                            // document.getElementById(
                            //   "output"
                            // ).innerHTML = dataObejcet.output.replace(/\n/g, "<br/>");
                            // document.getElementById(
                            //   "sample_output"
                            // ).innerHTML = dataObejcet.sample_output
                            //   .replace(/\n/g, "<br/>")
                            //   .replace(/ /g, "&nbsp;");

                            // if (dataObejcet.sample_input) {
                            //   this.sampleinputshow = true;
                            //   document.getElementById(
                            //     "sample_input"
                            //   ).innerHTML = dataObejcet.sample_input
                            //     .replace(/\n/g, "<br/>")
                            //     .replace(/ /g, "&nbsp;");
                            // } else {
                            //   this.sampleinputshow = false;
                            // }
                            // if (!dataObejcet.input || dataObejcet.input === "<p><br></p>") {
                            //   this.inputshow = false;
                            // } else {
                            //   this.inputshow = true;
                            //   document.getElementById(
                            //     "input"
                            //   ).innerHTML = dataObejcet.input.replace(/\n/g, "<br/>");
                            // }
                            // let hint = dataObejcet.hint;
                            // if (hint == null || hint.length === 0 || hint === "<p><br></p>") {
                            //   this.tipshow = false;
                            // } else {
                            //   this.tipshow = true;
                            //   document.getElementById(
                            //     "hint"
                            //   ).innerHTML = dataObejcet.hint.replace(/\n/g, "<br/>");
                            // }
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    Prism.highlightAll()
                                }, 0)
                            })
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err2', err)
                        $msg(err + "请求失败", 1);
                    });
            },

            getBjyUrl(id) {
                // console.log('getBjyUrl', id)
                fGet("/student/question/getVideo", {
                    problemId: id
                }).then(res => {
                    if (res.state === 'success') {
                        this.videoUrl = ''
                        if (!res.body) {
                            this.isVideo = false
                            window.$msg('当前题目暂无题解视频', 2)
                        } else {
                            let vId = res.body
                            this.isVideo = true
                            fVideoGet("/video/getBjyUrl", {
                                fileId: vId,
                            }).then(res => {
                                if (res.code === 0) {
                                    this.videoUrl = res.data
                                    this.videoVisible = true
                                } else {
                                    $msg(res.msg, 2);
                                }
                            }).catch(err => {
                                console.log(JSON.stringify(err))
                                $msg("请求失败", 1);
                            })
                        }
                    } else {
                        $msg(res.errMsg, 2);
                    }
                }).catch(err => {
                    console.log(JSON.stringify(err))
                    $msg("请求失败", 1);
                })
            },

            // 上一题
            gotoPreQuestion() {
                this.getQuestionListEachTime(1);
            },

            // 选择问题
            handleCommand(command) {
                for (let i = 0; i < this.questionList.length; i++) {
                    if (this.questionList[i].problem_id == command.problem_id) {
                        this.currentQuestionNum = i;
                    }
                }
                this.beginTime = formatTate(new Date())
                this.questionObject = command;
                this.getQuestionDetailById(command);
                this.getProblemUserById(command.problem_id)
            },

            // 获取语言
            getLanText(state) {
                switch (state) {
                    case 0: {
                        return 'C'
                    }
                    case 1: {
                        return 'C++'
                    }
                    case 2: {
                        return 'Pascal'
                    }
                    case 4: {
                        return 'Ruby'
                    }
                    case 5: {
                        return 'Bash'
                    }
                    case 6: {
                        return 'Python'
                    }
                    case 7: {
                        return 'php'
                    }
                    case 8: {
                        return 'perl'
                    }
                    case 9: {
                        return 'c#'
                    }
                    case 10: {
                        return 'objectiveC'
                    }
                    case 11: {
                        return 'freeBasic'
                    }
                    case 12: {
                        return 'scheme'
                    }
                    case 13: {
                        return 'Clang'
                    }
                    case 14: {
                        return 'Clang++'
                    }
                    case 15: {
                        return 'Lua'
                    }
                    case 16: {
                        return 'JavaScript'
                    }
                    case 17: {
                        return 'Go'
                    }
                    default : {
                        return ''
                    }
                }
            },

            // 下一题
            gotoAfterQuestion() {
                this.getQuestionListEachTime(2)
            },

            // 提交记录
            gotorecord() {
                this.$router.push({
                    path: "/student/submitRecord/",
                    query: {
                        contest_id: this.contest_id,
                        minHistoryTime: this.taskInfo.minHistoryTime,
                        lesson_id: this.lesson_id
                    }
                });
            },

            // 我的提交
            gotoresultdetail() {
                if (this.submittime != "暂无提交时间") {
                    this.questionObject.solutionBean.problemName = this.titleText;
                    this.questionObject.solutionBean.showTop = "show";
                    this.$router.push({
                        path: "/student/resultdetail/",
                        query: {
                            resultData: JSON.stringify(this.questionObject.solutionBean),
                            role: "admin"
                        }
                    });
                } else {
                    $msg("提交完题目在查看结果", 2);
                }
            },

            // 编辑代码
            editorCode() {
                if (this.submitFlag) {
                    this.submitFlag = false;
                    this.getTheLastCode();
                }
                this.showace = true;
                // document.getElementById("top").style.width = "50%";
                // document.getElementById("bottom").style.width = "50%";
            },

            //获取最后一次提交代码
            getTheLastCode() {
                // fGet("/student/question/getLastSubmit", {
                //   problem_id: this.problem_id,
                //   contest_id: this.contest_id,
                //   lesson_id: this.lesson_id,
                //     startTime:this.taskInfo.minHistoryTime
                // })
                //   .then(res => {
                //     if (res.state == "success") {
                //       this.questionObject.solutionBean = res.body;
                //       if (res.body.source != null) {
                //         this.resultNumber = res.body.result;
                //         this.submittime = res.body.in_date;
                //         this.onedata = res.body.time;
                //         this.twodata = res.body.memory;
                //         this.threedata = res.body.code_length;
                //         this.aceEditor.setValue(res.body.source);
                //       } else {
                //         this.submitFlag = false;
                //         this.resultNumber = -1;
                //         this.submittime = "暂无提交时间";
                //         this.onedata = 0;
                //         this.twodata = 0;
                //         this.threedata = 0;
                //       }
                //     } else {
                //       $msg(res.errMsg, 2);
                //     }
                //   })
                //   .catch(err => {
                //     console.log('err3', err)
                //     $msg('接口请求失败', 1)
                //   });
            },

            setmodemessage(codeItem) {
                this.currentLanguage = codeItem.value;
                this.currentCode = codeItem.name;
                this.aceEditor.getSession().setMode(codeItem.path);
            },
            handleWrapChange(wrap) {
                this.aceEditor.getSession().setUseWrapMode(wrap);
            },
            toggleConfigPanel() {
                this.toggle = !this.toggle;
            },

            // 收起代码编辑区
            closeCode() {
                // console.log(document.getElementsByClassName('splitter-paneR')[1].style.height = '0')
                if (this.isSubmitCode) {
                    this.isSubmitCode = false;
                    this.getTheLastCode();
                }
                this.showace = false;
            },

            // 提交代码
            submitCode() {
                if (this.aceEditor.getValue() === "") {
                    $msg("提交代码不能为空", 2);
                    return false;
                } else {
                    this.submitCodeText(this.aceEditor.getValue());
                }
                this.showresult = true;
            },

            submitCodeText(codeContent) {
                this.isSubmitCode = true;
                this.goonNumber = 0;
                this.currentResultNumber = 0;
                var enc = new TextEncoder("utf-8");
                fPost("/student/question/submitSolutionCode", {
                    problem_id: this.problem_id,
                    contest_id: this.contest_id,
                    lesson_id: this.lesson_id,
                    language: this.currentLanguage,
                    source: codeContent,
                    // time_cost: (this.endTime - this.beginTime) / 1000,
                    answer_time: this.beginTime,
                    in_date: formatTate(new Date()),
                    code_length: enc.encode(codeContent).length
                })
                    .then(res => {
                        if (res.state == "success") {
                            this.submitFlag = true
                            if (!this.taskInfo.finishTime) {
                                fPost("/student/task/finish", {
                                    taskId: this.$route.query.id
                                })
                                    .then(res => {
                                        if (res.state === "success") {
                                            this.$emit('updateView')
                                            // if (!this.statusDialog) {
                                            //   this.$emit('checkResult')
                                            // }
                                        } else {
                                            $msg(res.errMsg, 2);
                                        }
                                    })
                                    .catch(err => {
                                        $msg("接口请求失败", 1);
                                        console.log("err", err);
                                    });
                            }
                            this.canInterval = true;
                            this.currentSolutionId = res.body.solution_id;
                            this.interval = setInterval(this.getResponse, 1000);
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err4', err)
                        $msg('接口请求失败', 1)
                    });
            },

            getQuestionListEachTime(eventUporDown) {
                this.showresult = false;
                this.getresult = false;
                fGet("/student/question/getQuestionList", {
                    contest_id: this.contest_id,
                    startTime: this.taskInfo.minHistoryTime
                })
                    .then(res => {
                        if (res.state == "success") {
                            this.questionList = res.body;
                            if (eventUporDown == 1) {
                                this.currentQuestionNum--;
                                if (this.currentQuestionNum < 0) {
                                    $msg("已经是第一道题", 2);
                                    this.currentQuestionNum = 0;
                                    return false;
                                } else
                                    (this.beginTime = formatTate(new Date())),
                                        (this.questionObject = this.questionList[
                                            this.currentQuestionNum
                                            ]),
                                        this.getQuestionDetailById(
                                            this.questionList[this.currentQuestionNum]
                                        );
                                this.getProblemUserById(this.questionList[this.currentQuestionNum].problem_id)
                            } else {
                                this.currentQuestionNum++;
                                if (
                                    this.currentQuestionNum == this.questionList.length ||
                                    this.currentQuestionNum > this.questionList.length
                                ) {
                                    $msg("已经是最后一道题", 2);
                                    this.currentQuestionNum = this.questionList.length - 1;
                                    return false;
                                } else
                                    (this.beginTime = formatTate(new Date())),
                                        (this.questionObject = this.questionList[
                                            this.currentQuestionNum
                                            ]),
                                        this.getQuestionDetailById(
                                            this.questionList[this.currentQuestionNum]
                                        ),
                                        this.getProblemUserById(this.questionList[this.currentQuestionNum].problem_id)
                                        this.aceEditor.setValue("");
                            }
                        } else {
                            $msg(res.errMsg, 2);
                        }
                    })
                    .catch(err => {
                        console.log('err5', err)
                        $msg(err + "请求失败", 1);
                    });
            },

            //获取提交记录
            getResponse() {
                if (this.canInterval) {
                    fGet("/student/question/getSubmitHistoryItem", {
                        solution_id: this.currentSolutionId,
                    })
                        .then(res => {
                            if (res.state == "success") {
                                this.goonNumber++;
                                if (this.goonNumber == 3) {
                                    clearInterval(this.interval);
                                    this.canInterval = false;
                                    this.getresult = true;
                                    this.currentResultNumber = res.body.result;
                                    document.getElementById("bianyiText").innerText =
                                        "正在判题中，请稍后查看结果";
                                    return false;
                                }
                                if (res.body.result == 4) {
                                    // this.dialogVisible = true;
                                    this.successDialog = true
                                }
                                if (
                                    res.body.result != 0 &&
                                    res.body.result != 1 &&
                                    res.body.result != 2 &&
                                    res.body.result != 3
                                ) {
                                    clearInterval(this.interval);
                                    this.canInterval = false;
                                    this.getresult = true;
                                    this.currentResultNumber = res.body.result;
                                }
                                if (res.body.compileInfo != null && res.body.result == 11) {
                                    document.getElementById("bianyiText").innerText =
                                        res.body.compileInfo;
                                }
                                if (res.body.runtimeInfo != null && res.body.result == 10) {
                                    document.getElementById("runtimeText").innerText =
                                        res.body.runtimeInfo;
                                }
                                // 判断题库中的题目——都已经提交
                                // this.isFinishTopic().then(res => {
                                //   this.isFinish = res;
                                // });
                            } else {
                                $msg(res.errMsg, 2);
                            }
                        })
                        .catch(err => {
                            console.log('err6', err)
                            $msg('接口请求失败', 1)
                        });
                }
            },
            // 判断题库下-题目是否完成
            async isFinishTopic() {
                let isFinishTopic = null;
                let uncommitted = 0
                const {body} = await fGet("/student/question/getQuestionList", {
                    contest_id: this.contest_id,
                    startTime: this.taskInfo.minHistoryTime
                });
                const questionList = body;
                questionList.findIndex((item) => {
                    if (!item.solutionBean) {
                        uncommitted++
                    }
                });
                if (this.questionListData.length !== 0) {
                    if (uncommitted > 0) {
                        isFinishTopic = false
                    } else {
                        isFinishTopic = true
                    }
                } else {
                    isFinishTopic = false
                }
                return isFinishTopic;
            },

            previewVideo() {
                if (!this.submitFlag) {
                    window.$msg('请先提交代码后再查看题解视频', 2)
                } else {
                    this.$confirm('查看答案后答题记录不再计入统计，是否确定查看', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            this.getBjyUrl(this.questionList[this.currentQuestionNum].problem_id)
                            // this.videoVisible = true
                        })
                        .catch(() => {
                        })
                }
            },

            videoClose() {
                this.videoVisible = false
            },
        },
    }
</script>
<style lang='scss' scoped>
    .header-left {
        text-align: center;
        flex: 1;
        display: flex;
        justify-content: center;
        // margin-left: 350px;
        // margin-left: 23%;
    }

    .header {
        width: 100%;
        display: flex;
        padding-left: 15px;
        align-items: center;
        background: #212121;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
    }

    .components-container {
        position: relative;
        // height: 100vh;
        // height: 100%;
        // height: calc(100vh - 190px);
        height: calc(100vh - 60px);
        box-sizing: border-box;
        padding: 10px;
    }

    .left-container {
        // height: 100%;
        height: calc(100vh - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        // background-color: #F38181;
        background-color: #F8F9FA;
        border-right: 10px solid #DADADA;
    }

    .right-container {
        height: 100%;
        overflow: hidden;
        border-radius: 0;
        background-color: #FCE38A;
    }

    .top-container {
        // background-color: #FCE38A;
        // border-left: 5px solid #DADADA;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .bottom-container {
        // border-left: 5px solid #DADADA;
        width: 100%;
        // background-color: #95E1D3;
        background-color: rgb(61, 68, 76);
        height: 100%;
        overflow: auto;
    }

    /deep/ .el-main {
        padding: 0;
    }

    .btnbgmiddle {
        background-color: #171717;
        border: 1px solid #302604;
        line-height: 30px;
        margin-top: 10px;
    }

    .btnbg {
        line-height: 30px;
        background-color: #171717;
        border: 1px solid #302604;
        padding: 0 12px;
    }

    .tipclass {
        font-size: 20px;
        height: 30px;
        line-height: 30px;
        color: #25bb9b;
        padding: 0 5px;
    }

    .decription {
        line-height: 26px;
    }

    .header {
        width: 100%;
        display: flex;
        padding-left: 15px;
        align-items: center;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
    }

    .parentone {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        // margin-top: 5px;
        justify-content: center;
        background-color: #171717;
        border: 1px solid #302604;
        border-radius: 4px;
        line-height: 48px;
        margin: 0 10px;
        height: 48px;
        margin-top: 6px;
    }

    .questionPanel {
        display: inline-block;
        // margin-left:5px;
        // margin-bottom: 5px;
        color: #fff !important;
        border: none;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
    }

    .parenttwo {
        color: #f5c319;
    }

    .topqes {
        margin-left: 10px;
        margin-right: 50px;
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple {
        background: #f8f9fa;
    }

    .grid-content {
        min-height: 36px;
    }

    .parentitem {
        width: 96%;
        padding-top: 30px;
        padding-bottom: 30px;
        background: #ffffff;
        margin: 15px;
        padding-left: 30px;
    }

    .viticalline {
        width: 4px;
        background-color: #f5c319;
        height: 20px;
        margin-right: 15px;
    }

    .entercontent {
        width: 90%;
        border: solid 1px #a5a5a5;
        margin-top: 20px;
        padding: 15px;
    }

    .ace-editor {
        width: 100%;
        height: 100%;
        // min-height: 350px;
        overflow-y: auto;
    }

    .line {
        width: 15px;
        background: #e8e8e8;
        cursor: move;
        overflow: hidden;
    }

    .leftcls {
        width: 50%;
        min-width: 30%;
    }

    .parentall {
        display: flex;
        position: relative;
    }

    .el-dropdown-link {
        cursor: pointer;
    }

    .icon-list {
        color: #fff;
        font-size: 18px;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    .questionlist {
        width: 20%;
        margin-left: 20px;
        padding: 15px;
    }

    .rightParent {
        width: 50%;
        min-width: 30%;
    }

    .edittop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
    }

    .editbtn {
        width: 120px;
        height: 50px;
        line-height: 50px;
        background: rgba(245, 195, 25, 1);
        border-radius: 1px;
        margin: 30px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
    }

    .el-document {
        display: inline-block;
        margin-right: 8px;
    }

    .submmitbtn {
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: rgba(245, 195, 25, 1);
        border-radius: 1px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        margin-top: 3px;
        margin-right: 25px;
        cursor: default;
    }

    .rightitem {
        width: 90%;
        height: 180px;
        margin: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
        border-radius: 2px;
    }

    .rightcontent {
        padding: 25px;
    }

    .colcls {
        margin-top: 15px;
    }

    .showmessage {
        margin-top: 15px;
        display: flex;
    }

    .footer-condition {
        display: inline-block;
        margin-right: 20px;
        font-size: 14px;
        color: #c1c1c1;

        &:hover {
            color: #f5c319;
            cursor: pointer;
        }

        .el-icon-question {
            display: inline-block;
            margin-right: 5px;
        }

        .condition-con {
            p {
                margin-bottom: 10px;
            }
        }
    }

    /deep/ .splitter-pane-resizer.vertical {
        background: transparent;
        cursor: move;
    }

    /deep/ .splitter-pane-resizer {
        background: transparent;
        cursor: move;
    }

    /deep/ .el-dropdown {
        margin-right: 15px;
    }


    @media (max-width: 1280px) { //<=1280的设备
        .header-left {
            text-align: center;
            // margin-left: 14%;
        }
    }

    @media (max-width: 1366px) { //<=1366的设备
        .header-left {
            text-align: center;
            // margin-left: 18%;
        }
    }

    @media (max-width: 1400px) { //<=1400的设备
        .header-left {
            text-align: center;
            // margin-left: 20%;
        }
    }

    @media (max-width: 1600px) { //<=1600的设备
        .header-left {
            text-align: center;
            // margin-left: 22%;
        }
    }
</style>

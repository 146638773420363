var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container_con" },
    [
      _c("el-header", { staticClass: "header" }),
      _c(
        "el-main",
        { staticClass: "main" },
        [
          _c(
            "el-row",
            { staticClass: "box-line" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "任务说明", name: "acexplain" } },
                    [
                      _c("div", { staticClass: "parent" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "作品上传", name: "acupload" } },
                    [
                      !_vm.isUpload
                        ? _c(
                            "el-form",
                            {
                              ref: "ruleForm",
                              attrs: {
                                model: _vm.ruleForm,
                                "label-width": "100px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "作品名称：", prop: "name" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "70%" },
                                    model: {
                                      value: _vm.ruleForm.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "name", $$v)
                                      },
                                      expression: "ruleForm.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "作品上传：" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          ref: "upload",
                                          staticClass: "upload-demo",
                                          attrs: {
                                            "auto-upload": false,
                                            "before-remove": _vm.beforeRemove,
                                            "on-remove": _vm.handleAvatarRemove,
                                            "file-list": _vm.fileList,
                                            "on-success":
                                              _vm.handleAvatarSuccess,
                                            "on-error": _vm.handleAvatarFail,
                                            "on-exceed": _vm.handleExceed,
                                            action: _vm.uploadUrl,
                                            "on-change": _vm.handleChange,
                                            limit: 1,
                                            data: _vm.myData,
                                            name: "file",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                size: "small",
                                                type: "primary",
                                              },
                                              slot: "trigger",
                                            },
                                            [_vm._v("选取文件")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitUpload()
                                                },
                                              },
                                            },
                                            [_vm._v("上传到服务器")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(" 支持上传 "),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.supportList) +
                                                    " "
                                                ),
                                              ]),
                                              _vm._v(" 格式,文件限制范围"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.rowObject.minSize
                                                    ) + "KB"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                },
                                                [_vm._v("~")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.rowObject.maxSize
                                                    ) + "KB"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isUpload
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "center",
                                width: "100%",
                                "align-items": "center",
                                "margin-top": "30px",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../../../assets/images/right.png"),
                                },
                              }),
                              _c(
                                "span",
                                { staticStyle: { "margin-top": "30px" } },
                                [
                                  _vm._v(
                                    "作品已上传,如需重新上传，请联系老师删除作品后，再上传"
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
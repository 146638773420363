import { render, staticRenderFns } from "./studentQuestion.vue?vue&type=template&id=51c91837&scoped=true"
import script from "./studentQuestion.vue?vue&type=script&lang=js"
export * from "./studentQuestion.vue?vue&type=script&lang=js"
import style0 from "./studentQuestion.vue?vue&type=style&index=0&id=51c91837&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c91837",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_business_lcPT/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51c91837')) {
      api.createRecord('51c91837', component.options)
    } else {
      api.reload('51c91837', component.options)
    }
    module.hot.accept("./studentQuestion.vue?vue&type=template&id=51c91837&scoped=true", function () {
      api.rerender('51c91837', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/viewsteacher/courseTrain/meredu/components/studentQuestion.vue"
export default component.exports
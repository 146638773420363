<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    width="60%"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>任务说明</span>
    </div>

    <!-- taskInfo: {{taskInfo}} -->

    <el-row v-html="description"></el-row>

    <!-- <el-row slot="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="close" type="info">取 消</el-button>
    </el-row> -->
  </el-dialog>
</template>

<script>
import Prism from "prismjs"; //引入插件

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    dialogData: {
      default: () => {
        return {}
      },
      type: Object
    },
    taskInfo: {
      default: () => {
        return {}
      }
    }
  },
  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      diaTitle: '',
      formData: {},
      rules: {},
      description: ''
    }
  },
  methods: {
    openDialog () {
      if(this.taskInfo.type === "goc") {
        this.description = JSON.parse(this.taskInfo.sourceContent).description
      }else if(this.taskInfo.type === "scratch") {
        this.description = this.taskInfo.description
      }else if(this.taskInfo.type === "Pygame") {
        this.description = this.taskInfo.description
      }
      this.$nextTick(() => {
        setTimeout(()=> {
          Prism.highlightAll()
        }, 100)
      })
    },

    confirm () {},

    close () {
      this.$emit('close')
    }
  }
}
</script>


import $axios from '@/utils/request'


//-----------------------------------------个人素材---------------------------------------------
// 素材列表
export function listSource(data) {
    return $axios.fPost("/merchant/user/material/listByMy", data || {})
}

// 新增素材
export function addSource(data) {
    return $axios.fPost("/merchant/user/material/insert", data || {})
}

// 编辑素材
export function editSource(data) {
  return $axios.fPost("/merchant/user/material/update", data || {})
}

// 删除素材
export function delSource(data) {
  return $axios.fPost("/merchant/user/material/delete", data || {})
}
//-----------------------------------------/个人素材---------------------------------------------

//-----------------------------------------作品素材---------------------------------------------

// 作品素材列表
export function listWorksSource(data) {
  return $axios.fPost("/merchant/creation/material/listByCreation", data || {})
}
// 删除
export function delWorksSource(data) {
  return $axios.fPost("/merchant/creation/material/delete", data || {})
}

// 新增关联
export function addWorksSource(data) {
  return $axios.fPost("/merchant/creation/material/insert", data || {})
}
// 编辑
export function editWorksSource(data) {
  return $axios.fPost("/merchant/creation/material/update", data || {})
}

//-----------------------------------------/作品素材---------------------------------------------


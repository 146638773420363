<template>
  <el-container>
    <!-- header -->
    <el-header style="background-color: #212121">
      <el-row style="text-align: center">
        <el-col :span="6" style="height: 60px;"></el-col>
        <el-col :span="3" style="line-height: 60px;">
          <div class="parenttwo">
            <el-button
              @click="gotoPreQuestion"
              class="btnbg"
              icon="el-icon-arrow-left"
              type="text"
            ></el-button>
          </div>
        </el-col>
        <el-col :span="6" class="btnbgmiddle">
          <div class="parentone">
            <span
              class="titlequestion questionPanel"
            >{{ titleText }}</span>
            <div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <span
                    class="el-icon-arrow-down"
                    style="top: 0px; left: 0px;font-size: 16px;color: #FFFFFF"
                  ></span>
                </span>
                <el-dropdown-menu class="questionlist" slot="dropdown">
                  <el-dropdown-item
                    :command="item"
                    :key="index"
                    v-for="(item, index) in questionList"
                  >{{ index + 1 }}.&nbsp;&nbsp;{{ item.problemName }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
        <el-col :span="3" style="line-height: 60px;">
          <div class="parenttwo">
            <el-button @click="gotoAfterQuestion" class="btnbg" type="text">
              <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
        </el-col>
        <el-col :span="6" style="line-height: 60px;">
          <div
            style="margin-right: 120px;background-color: #f5c319;color: #000000;border-radius: 20px;width: 120px;height:35px;line-height: 35px;margin-top: 13px;float: right;text-align: center;font-size: 14px;cursor: pointer"
            v-on:click="gotorecord()"
          >提交记录</div>
        </el-col>
      </el-row>
    </el-header>
    <!-- 主体 -->
    <el-main style="width: 100%; height: 100%;overflow: hidden;">
      <div class="parentall" id="box">
        <div
          :style="height"
          class="grid-content bg-purple leftcls"
          id="top"
          style="overflow-y: auto;overflow-x: hidden"
        >
          <el-row>
            <el-col class="parentitem">
              <div class="titlequestion">{{ titleText }}</div>
              <div class="decription" style="margin-top: 20px;">
                时间限制：{{
                timeLimit
                }}秒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>内存限制：{{ memoryLimit }}M</span>
              </div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion" style="display: flex">题目描述</div>
              <div class="decription" id="description" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem" v-show="inputshow">
              <div class="titlequestion" style="display: flex">输入描述</div>
              <div class="decription" id="input" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion" style="display: flex">输出描述</div>
              <div class="decription" id="output" style="margin-top: 20px;"></div>
            </el-col>
            <el-col class="parentitem">
              <div class="titlequestion">样例</div>
              <div v-show="sampleinputshow">
                <div class="decription" style="margin-top: 20px;">输入</div>
                <div class="entercontent" id="sample_input"></div>
              </div>
              <div class="decription" style="margin-top: 20px;">输出</div>
              <div class="entercontent" id="sample_output"></div>
            </el-col>
            <el-col class="parentitem" v-show="tipshow">
              <div class="titlequestion" style="display: flex">提示</div>
              <div class="decription" id="hint" style="margin-top: 20px;margin-bottom: 80px;"></div>
            </el-col>
          </el-row>
        </div>
        <div @mousedown="lineMouse($event)" class="line" id="line"></div>

        <!-- <div :style="height" class="rightParent" id="bottom"> -->
        <div style="height: 100%; overflow: hidden;" class="rightParent" id="bottom">
          <div class="rightitem" v-show="!showace">
            <div @click="gotoresultdetail" style="cursor:pointer;">
              <el-row class="rightcontent">
                <el-col class="titlequestion">我的提交</el-col>
                <el-col class="secondtitle colcls">
                  <span>提交时间：&nbsp;</span>
                  <span>{{submittime | dateformat}}</span>
                </el-col>
                <el-col class="secondtitle colcls">
                  <span>提交结果：&nbsp;</span>
                  <span :style="'color' + ':' + getStateColor(resultNumber)" v-text="getSubType(resultNumber)"></span>
                </el-col>
                <el-col class="decription showmessage">
                  <div>
                    <i class="iconfont icon-yongshi ico-time"></i>
                    <el-tooltip content="用时" placement="top">
                      <span style="margin-left: 5px;" class="pointer">{{ onedata }}ms</span>
                    </el-tooltip>
                  </div>
                  <div style="margin-left: 15px;">
                    <i class="iconfont icon-qian ico-bulk"></i>
                    <el-tooltip content="内存" placement="top">
                      <span style="margin-left: 5px;" class="pointer">{{ twodata }}KB</span>
                    </el-tooltip>
                  </div>
                  <div style="margin-left: 15px;">
                    <i class="iconfont icon-storage-capacity-copy"></i>
                    <el-tooltip content="代码长度" placement="top">
                      <span style="margin-left: 5px;" class="pointer">{{ threedata }}B</span>
                    </el-tooltip>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-col>
              <el-button
                style="margin-top: 60px;margin-left: 30px"
                type="warning"
                v-on:click="editorCode()"
              >编辑代码</el-button>
            </el-col>
          </div>
          <div
            id="boxtwo"
            style="display: flex;flex-direction: column;background: #181E22;"
            v-show="showace"
          >
            <div class="edittop">
              <el-dropdown @command="setmodemessage">
                <el-button
                  style="background: #516169;border:none; margin-left: 10px;"
                  type="primary"
                >
                  {{ currentCode}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" style="width: 8%;text-align: center">
                  <el-dropdown-item
                    :command="item"
                    :key="index"
                    v-for="(item, index) in modeArray"
                  >{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div>
                <el-button
                  style="background: #516169;border:none; margin-right: 15px;"
                  type="primary"
                  v-on:click="closeCode()"
                >
                  <i class="el-icon-share" style="margin-right: 10px;"></i>收起代码编辑区
                </el-button>
              </div>
            </div>
            <div :style="middleheight" class="ace-editor" id="toptwo" ref="ace"></div>

            <div @mousedown="topMouse($event)" id="linetwo" style=" cursor: s-resize;">
              <el-button
                style="margin: 10px;"
                type="warning"
                v-on:click="submitCode()"
                v-prevent-re-click="5000"
              >提交代码</el-button>
            </div>
            <div
              :style="footerheight"
              id="bottomtwo"
              style="background-color: #3d444c;"
              v-show="showresult"
            >
              <div id="tipsone" v-show="!getresult">
                <div class="tipclass">您的代码已经保存</div>
                <div class="tipclass">正在查询结果...</div>
              </div>
              <div id="tipstwo" v-show="getresult">
                <div class="tipclass">
                  <span
                    :style="'color' + ':' + getStateColor(currentResultNumber)"
                    v-text="getSubType(currentResultNumber)"
                  ></span>
                </div>
                <div style="height: 217px;overflow-y: auto">
                  <div class="tipclass" id="bianyiText"></div>
                  <div class="tipclass" id="runtimeText"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 代码提交正确的提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="30%"
      top="7vh"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <i
        @click="dialogVisible = false"
        class="el-icon-error"
        style="float: right;font-size: 25px;color: #FFFFFF;cursor: pointer;"
      ></i>
      <img class="animated infinite pulse" src="../../../../assets/images/success.png" />
    </el-dialog>
  </el-container>
</template>
<script>
import ace from "ace-builds";
import getSubType from "@/mixins/status";
import getStateColor from "@/mixins/status";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/java";
import "ace-builds/src-noconflict/snippets/c_cpp";
import "ace-builds/src-noconflict/snippets/pascal";
import "ace-builds/src-noconflict/snippets/ruby";
import "ace-builds/src-noconflict/snippets/batchfile";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-noconflict/snippets/php";
import "ace-builds/src-noconflict/snippets/perl";
import "ace-builds/src-noconflict/snippets/objectivec";
import "ace-builds/src-noconflict/snippets/scheme";
import "ace-builds/src-noconflict/snippets/clojure";
import "ace-builds/src-noconflict/snippets/lua";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/golang";
import "ace-builds/src-noconflict/snippets/gobstones";
import "ace-builds/src-noconflict/snippets/django";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-clouds_midnight";
import "ace-builds/src-noconflict/mode-javascript";

const modeArray = [
  {
    name: "C",
    path: "ace/mode/c_cpp",
    value: 0
  },
  {
    name: "C++",
    path: "ace/mode/c_cpp",
    value: 1
  },
  {
    name: "JavaScript",
    path: "ace/mode/javascript",
    value: 16
  },
  {
    name: "Java",
    path: "ace/mode/java",
    value: 3
  },
  {
    name: "Python",
    path: "ace/mode/python",
    value: 6
  },
  {
    name: "php",
    path: "ace/mode/php",
    value: 7
  },
  {
    name: "Pascal",
    path: "ace/mode/pascal",
    value: 2
  },
  {
    name: "Ruby",
    path: "ace/mode/ruby",
    value: 4
  },
  {
    name: "freeBasic",
    path: "ace/mode/batchfile",
    value: 11
  },
  {
    name: "perl",
    path: "ace/mode/perl",
    value: 8
  },
  {
    name: "c#",
    path: "ace/mode/c_cpp",
    value: 9
  },
  {
    name: "objectiveC",
    path: "ace/mode/objectivec",
    value: 10
  },
  {
    name: "scheme",
    path: "ace/mode/scheme",
    value: 12
  },
  {
    name: "Lua",
    path: "ace/mode/lua",
    value: 15
  },
  {
    name: "Go",
    path: "ace/mode/golang",
    value: 17
  }
];
export default {
  props: {
    value: String,
    contest_id: {
      default: "",
      type: String
    },
    taskID: {
      default: "",
      type: String
    }
  },
  mounted() {
    this.aceEditor = ace.edit(this.$refs.ace, {
      fontSize: 20,
      value: this.value ? this.value : "",
      theme: this.themePath,
      mode: this.modePath,
      wrap: this.wrap,
      tabSize: 4
    });
    ace.require("ace/ext/language_tools");
    // 激活自动提示
    this.aceEditor.setOptions({
      enableSnippets: true,
      enableLiveAutocompletion: true,
      enableBasicAutocompletion: true
    });
    this.aceEditor.getSession().on("change", this.change);
  },
  mixins: [getSubType, getStateColor],
  data() {
    return {
      dialogVisible: false,
      canInterval: true,
      number: 0,
      aceEditor: null,
      toggle: false,
      wrap: true,
      showace: true,
      showresult: false,
      currentCode: "C++",
      themePath: "ace/theme/monokai",
      modePath: "ace/mode/c_cpp",
      modeArray: modeArray,
      currentQuestionNum: 0,
      currentResultNumber: 2,
      currentLanguage: 1,
      questionList: [],
      titleText: "",
      timeLimit: "",
      memoryLimit: "",
      input: "",
      output: "",
      resultNumber: 0,
      submittime: "",
      onedata: "",
      twodata: "",
      threedata: "",
      submitFlag: false,
      questionObject: {},
      getresult: false,
      currentSolutionId: 0,
      beginTime: 0,
      endTime: 0,
      problem_id: 0,
      goonNumber: 0,
      interval: 0,
      lesson_id: "",
      sampleinputshow: true,
      inputshow: true,
      tipshow: true,
      isSubmitCode: false,
      height: {
        height: ""
      },
      middleheight: {
        height: "",
        maxHeight: ""
      },
      footerheight: {
        height: ""
      }
    };
  },
  created() {
    this.getQuestionList();
    this.changeHight();
    this.beginTime = new Date().getTime();
  },
  methods: {
    //获取最后一次提交代码
    getTheLastCode() {
      fGet("/student/question/getLastSubmit", {
        problem_id: this.problem_id,
        contest_id: this.contest_id,
        lesson_id: this.lesson_id
      })
        .then(res => {
          if (res.state == "success") {
            this.questionObject.solutionBean = res.body;
            if (res.body.source != null) {
              this.resultNumber = res.body.result;
              this.submittime = res.body.in_date;
              this.onedata = res.body.time;
              this.twodata = res.body.memory;
              this.threedata = res.body.code_length;
              this.aceEditor.setValue(res.body.source);
            } else {
              this.submitFlag = false;
              this.resultNumber = -1;
              this.submittime = "暂无提交时间";
              this.onedata = 0;
              this.twodata = 0;
              this.threedata = 0;
            }
          } else {
          }
        })
        .catch(err => {});
    },

    gotoPreQuestion() {
      this.getQuestionListEachTime(1);
    },
    gotoAfterQuestion() {
      this.getQuestionListEachTime(2);
    },
    submitCodeText(codeContent) {
      this.isSubmitCode = true;
      this.goonNumber = 0;
      this.currentResultNumber = 0;
      var enc = new TextEncoder("utf-8");
      fPost("/student/question/submitSolutionCode", {
        problem_id: this.problem_id,
        contest_id: this.contest_id,
        lesson_id: this.lesson_id,
        language: this.currentLanguage,
        source: codeContent,
        time_cost: (this.endTime - this.beginTime) / 1000,
        code_length: enc.encode(codeContent).length
      })
        .then(res => {
          if (res.state == "success") {
            this.canInterval = true;
            this.currentSolutionId = res.body.solution_id;
            this.interval = setInterval(this.getResponse, 1000);
          } else {
          }
        })
        .catch(err => {});
    },
    //获取提交记录
    getResponse() {
      if (this.canInterval) {
        fGet("/student/question/getSubmitHistoryItem", {
          solution_id: this.currentSolutionId
        })
          .then(res => {
            if (res.state == "success") {
              this.goonNumber++;
              if (this.goonNumber == 3) {
                clearInterval(this.interval);
                this.canInterval = false;
                this.getresult = true;
                this.currentResultNumber = res.body.result;
                document.getElementById("bianyiText").innerText =
                  "正在判题中，请稍后查看结果";
                return false;
              }
              if (res.body.result == 4) {
                this.dialogVisible = true;
              }
              if (
                res.body.result != 0 &&
                res.body.result != 1 &&
                res.body.result != 2 &&
                res.body.result != 3
              ) {
                clearInterval(this.interval);
                this.canInterval = false;
                this.getresult = true;
                this.currentResultNumber = res.body.result;
              }
              if (res.body.compileInfo != null && res.body.result == 11) {
                document.getElementById("bianyiText").innerText =
                  res.body.compileInfo;
              }
              if (res.body.runtimeInfo != null && res.body.result == 10) {
                document.getElementById("runtimeText").innerText =
                  res.body.runtimeInfo;
              }
            } else {
            }
          })
          .catch(err => {});
      }
    },
    getQuestionDetailById(thembQuestionOj) {
      this.showresult = false;
      this.getresult = false;
      this.footerheight.height = 0 + "px";
      // this.middleheight.height = document.body.clientHeight - 340 + 'px';
      this.middleheight.height = window.screen.height - 320 + "px";
      document.getElementById("bianyiText").innerText = "";
      document.getElementById("runtimeText").innerText = "";
      this.problem_id = thembQuestionOj.problem_id;
      if (
        thembQuestionOj.solutionBean != null &&
        thembQuestionOj.solutionBean != "null"
      ) {
        this.resultNumber = thembQuestionOj.solutionBean.result;
        this.submittime = thembQuestionOj.solutionBean.in_date;
        this.onedata = thembQuestionOj.solutionBean.time;
        this.twodata = thembQuestionOj.solutionBean.memory;
        this.threedata = thembQuestionOj.solutionBean.code_length;
      } else {
        this.resultNumber = -1;
        this.submittime = "暂无提交时间";
        this.onedata = 0;
        this.twodata = 0;
        this.threedata = 0;
      }

      this.showace = !thembQuestionOj._submit;
      this.submitFlag = thembQuestionOj._submit;
      fGet("/student/question/getQuestionById", {
        problem_id: thembQuestionOj.problem_id
      })
        .then(res => {
          if (res.state == "success") {
            var dataObejcet = res.body[0];
            this.titleText = dataObejcet.title;
            this.timeLimit = dataObejcet.time_limit;
            this.memoryLimit = dataObejcet.memory_limit;
            document.getElementById(
              "description"
            ).innerHTML = dataObejcet.description.replace(/\n/g, "<br/>");
            document.getElementById(
              "output"
            ).innerHTML = dataObejcet.output.replace(/\n/g, "<br/>");
            document.getElementById(
              "sample_output"
            ).innerHTML = dataObejcet.sample_output
              .replace(/\n/g, "<br/>")
              .replace(/ /g, "&nbsp;");

            if (dataObejcet.sample_input) {
              this.sampleinputshow = true;
              document.getElementById(
                "sample_input"
              ).innerHTML = dataObejcet.sample_input
                .replace(/\n/g, "<br/>")
                .replace(/ /g, "&nbsp;");
            } else {
              this.sampleinputshow = false;
            }
            if (!dataObejcet.input || dataObejcet.input === "<p><br></p>") {
              this.inputshow = false;
            } else {
              this.inputshow = true;
              document.getElementById(
                "input"
              ).innerHTML = dataObejcet.input.replace(/\n/g, "<br/>");
            }
            let hint = dataObejcet.hint;
            if (hint == null || hint.length === 0 || hint === "<p><br></p>") {
              this.tipshow = false;
            } else {
              this.tipshow = true;
              document.getElementById(
                "hint"
              ).innerHTML = dataObejcet.hint.replace(/\n/g, "<br/>");
            }
          } else {
            $msg(res.errMsg, 2);
          }
        })
        .catch(err => {
          $msg(err + "请求失败", 1);
        });
    },

    // 获取题库的问题
    getQuestionList() {
      fGet("/student/question/getQuestionList", {
        contest_id: this.contest_id
      })
        .then(res => {
          if (res.state == "success") {
            if (res.body.length == 0) {
              $msg(
                "题目未开放，请联系老师(contest_id=" + this.contest_id + ")",
                2
              );
              return false;
            }
            this.currentQuestionNum = 0;
            this.questionList = res.body;
            this.questionObject = this.questionList[0];
            this.getQuestionDetailById(this.questionList[0]);
          } else {
            $msg(res.errMsg, 2);
          }
        })
        .catch(err => {
          $msg(err + "请求失败", 1);
        });
    },
    getQuestionListEachTime(eventUporDown) {
      this.showresult = false;
      this.getresult = false;
      this.footerheight.height = 0 + "px";
      // this.middleheight.height = document.body.clientHeight - 340 + 'px';
      this.middleheight.height = window.screen.height - 320 + "px";
      document.getElementById("bianyiText").innerText = "";
      document.getElementById("runtimeText").innerText = "";
      fGet("/student/question/getQuestionList", {
        contest_id: this.contest_id
      })
        .then(res => {
          if (res.state == "success") {
            this.questionList = res.body;
            if (eventUporDown == 1) {
              this.currentQuestionNum--;
              if (this.currentQuestionNum < 0) {
                $msg("已经是第一道题", 2);
                this.currentQuestionNum = 0;
                return false;
              } else
                (this.beginTime = new Date().getTime()),
                  (this.questionObject = this.questionList[
                    this.currentQuestionNum
                  ]),
                  this.getQuestionDetailById(
                    this.questionList[this.currentQuestionNum]
                  );
            } else {
              this.currentQuestionNum++;
              if (
                this.currentQuestionNum == this.questionList.length ||
                this.currentQuestionNum > this.questionList.length
              ) {
                $msg("已经是最后一道题", 2);
                this.currentQuestionNum = this.questionList.length - 1;
                return false;
              } else
                (this.beginTime = new Date().getTime()),
                  (this.questionObject = this.questionList[
                    this.currentQuestionNum
                  ]),
                  this.getQuestionDetailById(
                    this.questionList[this.currentQuestionNum]
                  ),
                  this.aceEditor.setValue("");
            }
          } else {
            $msg(res.errMsg, 2);
          }
        })
        .catch(err => {
          $msg(err + "请求失败", 1);
        });
    },
    gotoresultdetail() {
      if (this.submittime != "暂无提交时间") {
        this.questionObject.solutionBean.problemName = this.titleText;
        this.questionObject.solutionBean.showTop = "show";
        this.$router.push({
          path: "/student/resultdetail/",
          query: {
            resultData: JSON.stringify(this.questionObject.solutionBean),
            role: "admin"
          }
        });
      } else {
        $msg("提交完题目在查看结果", 2);
      }
    },

    gotorecord() {
      this.$router.push({
        path: "/student/submitRecord/",
        query: {
          contest_id: this.contest_id,
          lesson_id: this.lesson_id
        }
      });
    },
    editorCode() {
      if (this.submitFlag) {
        this.submitFlag = false;
        this.getTheLastCode();
      }
      this.showace = true;
      document.getElementById("top").style.width = "50%";
      document.getElementById("bottom").style.width = "50%";
    },
    closeCode() {
      if (this.isSubmitCode) {
        this.isSubmitCode = false;
        this.getTheLastCode();
      }
      this.showace = false;
      document.getElementById("top").style.width = "50%";
      document.getElementById("bottom").style.width = "50%";
    },

    // 头部问题面板-选择问题
    handleCommand(command) {
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].problem_id == command.problem_id) {
          this.currentQuestionNum = i;
        }
      }
      this.beginTime = new Date().getTime();
      this.questionObject = command;
      this.getQuestionDetailById(command);
    },

    lineMouse(e) {
      if (!this.showace) {
        return false;
      }
      let oBox = document.getElementById("box");
      let oTop = document.getElementById("top");
      let oBottom = document.getElementById("bottom");
      let oLine = document.getElementById("line");
      var disX = (e || event).clientX;

      oLine.left = oLine.offsetLeft;
      document.onmousemove = function(e) {
        var iT = oLine.left + ((e || event).clientX - disX);
        var maxT = oBox.clientWight - oLine.offsetWidth;
        oLine.style.margin = 0;
        iT < 0 && (iT = 0);
        iT > maxT && (iT = maxT);
        oLine.style.left = oTop.style.width = iT + "px";
        oBottom.style.width = oBox.clientWidth - iT + "px";
        return false;
      };
      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
        oLine.releaseCapture && oLine.releaseCapture();
      };
      oLine.setCapture && oLine.setCapture();
      return false;
    },

    // 拖拽显示 判题机运行结果
    topMouse(e) {
      if (!this.showresult) {
        return false;
      }
      let oBox = document.getElementById("boxtwo");
      let oTop = document.getElementById("toptwo");
      let oBottom = document.getElementById("bottomtwo");
      let oLine = document.getElementById("linetwo");
      var disY = (e || event).clientY;

      oLine.top = oLine.offsetTop;
      document.onmousemove = function(e) {
        var iT = oLine.top + ((e || event).clientY - disY);
        var e = e || window.event,
          tarnameb = e.target || e.srcElement;
        var maxT = oBox.clientHeight - oLine.offsetHeight;
        oLine.style.margin = 0;
        iT < 0 && (iT = 0);
        iT > maxT && (iT = maxT);
        oLine.style.top = oTop.style.height = iT + "px";
        oBottom.style.height = oBox.clientHeight - iT + "px";
        return false;
      };
      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
        oLine.releaseCapture && oLine.releaseCapture();
      };
      oLine.setCapture && oLine.setCapture();
      return false;
    },

    // 提交代码
    submitCode() {
      if (this.aceEditor.getValue() == "") {
        $msg("提交代码不能为空", 2);
        return false;
      } else {
        this.endTime = new Date().getTime();
        this.submitCodeText(this.aceEditor.getValue());
      }
      this.showresult = true;
      // this.footerheight.height = document.body.clientHeight - 690 + "px";
      // this.middleheight.height = document.body.clientHeight - 650 + "px";
      this.footerheight.height = window.screen.height / 3 + "px";
      this.middleheight.height = window.screen.height - 650 + "px";

      // 判断题库中的题目——都已经提交
      this.isFinishTopic().then(res => {
        const isFinish = res;
        this.$emit("isFinish", isFinish);
        // if(isFinish) {
        //   fPost('/student/task/finish', {
        //     taskId: this.taskID
        //   }).then(res=> {
        //     if(res.state === 'success') {
        //     } else {
        //       $msg(res.errMsg, 2)
        //     }
        //   })
        // }
      });
    },
    
    changeHight() {
      this.height.height = window.screen.height - 210 + "px";
      console.log('height', this.height.height)
      this.middleheight.maxHeight = window.screen.height - 350 + "px";
      this.middleheight.height = window.screen.height - 320 + "px";
      // this.height.height = document.body.clientHeight - 230 + 'px'
      // this.middleheight.height = document.body.clientHeight - 340 + 'px';
    },
    toggleConfigPanel() {
      this.toggle = !this.toggle;
    },
    change() {
      this.$emit("input", this.aceEditor.getSession().getValue());
    },
    setmodemessage(codeItem) {
      this.currentLanguage = codeItem.value;
      this.currentCode = codeItem.name;
      this.aceEditor.getSession().setMode(codeItem.path);
    },
    handleWrapChange(wrap) {
      this.aceEditor.getSession().setUseWrapMode(wrap);
    },

    // 判断题库下-题目是否完成
    async isFinishTopic() {
      let isFinishTopic = null;
      const { body } = await fGet("/student/question/getQuestionList", {
        contest_id: this.contest_id
      });
      const questionList = body;
      questionList.forEach((item, index) => {
        if (!item.solutionBean) {
          isFinishTopic = false;
          return false;
        } else {
          isFinishTopic = true;
        }
      });
      return isFinishTopic;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
/deep/ .el-dialog {
  background: transparent;
  border: transparent 0px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btnbgmiddle {
  background-color: #171717;
  border: 1px solid #302604;
  line-height: 30px;
  margin-top: 10px;
}
.btnbg {
  line-height: 30px;
  background-color: #171717;
  border: 1px solid #302604;
  padding: 0 12px;
}
.tipclass {
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  color: #25bb9b;
  padding: 0 5px;
}
.decription {
  line-height: 26px;
}
.header {
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
}

.parentone {
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
}

.questionPanel {
  display: inline-block;
  margin-left:5px;
  margin-bottom: 5px;
  color: #fff !important;
  border: none; width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
}

.parenttwo {
  color: #f5c319;
}

.topqes {
  margin-left: 10px;
  margin-right: 50px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #f8f9fa;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.parentitem {
  width: 96%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  margin: 15px;
  padding-left: 30px;
}

.viticalline {
  width: 4px;
  background-color: #f5c319;
  height: 20px;
  margin-right: 15px;
}

.entercontent {
  width: 90%;
  border: solid 1px #a5a5a5;
  margin-top: 20px;
  padding: 15px;
}

.ace-editor {
  width: 100%;
  min-width: 30%;
  min-height: 350px;
  overflow-y: hidden;
}

.line {
  width: 15px;
  background: #e8e8e8;
  cursor: move;
  overflow: hidden;
}

.leftcls {
  width: 50%;
  min-width: 30%;
}

.parentall {
  display: flex;
  position: relative;
}

.el-dropdown-link {
  cursor: pointer;
}

.icon-list {
  color: #fff;
  font-size: 18px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.questionlist {
  width: 20%;
  margin-left: 20px;
  padding: 15px;
}

.rightParent {
  width: 50%;
  min-width: 30%;
}

.edittop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.editbtn {
  width: 120px;
  height: 50px;
  line-height: 50px;
  background: rgba(245, 195, 25, 1);
  border-radius: 1px;
  margin: 30px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
}

.submmitbtn {
  width: 120px;
  height: 40px;
  line-height: 40px;
  background: rgba(245, 195, 25, 1);
  border-radius: 1px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  margin-top: 3px;
  margin-right: 25px;
  cursor: default;
}

.rightitem {
  width: 90%;
  height: 180px;
  margin: 20px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}

.rightcontent {
  padding: 25px;
}

.colcls {
  margin-top: 15px;
}

.showmessage {
  margin-top: 15px;
  display: flex;
}
</style>

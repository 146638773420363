<template>
  <el-container class="container">
    <el-header
      class="header"
      style="display: block; line-height: 60px"
      v-if="taskInfo.type !== '编程'"
    >
      <span class="fl">{{ taskInfo.title }}</span>
      <el-row class="fr" v-if="taskID">
        <template v-if="!taskInfo.finishTime">
          <el-popover
            class="footer-condition"
            placement="top-end"
            trigger="click"
            width="150"
          >
            <el-row class="condition-con">
              <p>满足以下条件：</p>
              <p
                v-if="
                  taskInfo.type === '图文' ||
                  taskInfo.type === '外链' ||
                  taskInfo.type === 'python' ||
                  taskInfo.type === 'pdf' ||
                  taskInfo.type === '视频' ||
                  taskInfo.type === 'ppt'
                "
              >
                至少观看{{ taskPremise }}分钟
              </p>
              <p v-else-if="taskInfo.type === '编程'">完成所有已开放的题目</p>
              <p v-else-if="taskInfo.type === '测试'">提交试卷</p>
              <p v-else-if="taskInfo.type === '上传'">完成上传作品</p>
              <p v-else-if="taskInfo.type === 'goc'">
                <span v-if="gocSourceContent.type === '代码'">提交代码</span>
                <span v-else>至少观看{{ taskPremise }}分钟</span>
              </p>
              <p v-else-if="taskInfo.type === 'scratch'">保存作品</p>
              <p v-else-if="taskInfo.type === 'Pygame'">保存作品</p>
            </el-row>
            <span slot="reference">
              <span class="el-icon-question"></span>任务完成条件
            </span>
          </el-popover>
          <template>
            <el-button
              :disabled="isEnd"
              @click="taskFinish"
              class="footer-btn"
              round
              size="small"
              type="primary"
            >完成任务
            </el-button
            >
          </template>
        </template>
        <template v-else>
          <el-button
            @click="nextTask()"
            class="footer-btn"
            round
            size="small"
            type="primary"
          >已完成、进入下个任务
          </el-button
          >
        </template>
        <template>
          <el-button
            @click="taskListHandler"
            class="footer-btn"
            round
            size="small"
            type="primary"
          >任务列表
          </el-button
          >
        </template>
      </el-row>

      <el-row class="fr" v-if="taskInfo.type === 'scratch'">
        <el-row class="fr">
          <div style="display: flex;align-items: center;line-height: 60px;height: 60px;">
            <el-carousel ref="scratchCarousel" height="45px" style="width:300px;margin-right: 20px;border-radius: 50px;"
                         :autoplay="autoplay" :loop="autoplay"
                         @change="scratchQuestionChange" arrow="always">
              <el-carousel-item v-for="(item,index) in scratchQuestionForTaskList" :key="index">
                <span class="small">第{{ index + 1 }}道题</span>
              </el-carousel-item>
            </el-carousel>
            <template v-if="isChooseScratch">
            <span style="margin-right: 15px">
              作品名称：{{ scratchChoose }}
            </span>
              <el-button
                size="small"
                type="primary"
                round
                @click="saveScratch('1')"
                style="margin-right: 15px"
                :loading="isGoShow"
              >保 存
              </el-button
              >
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form
                  ref="taskPoverForm"
                  :model="formData"
                  :rules="rules"
                  label-width="100px"
                >
                  <el-form-item label="作品名称：" prop="titleBesides">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.titleBesides"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="saveScratch('2')"
                  >确 定
                  </el-button
                  >
                  <el-button type="info" size="mini" @click="taskClose"
                  >取 消
                  </el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  size="small"
                  type="primary"
                  round
                  style="margin-right: 15px"
                  :loading="isGoShow"
                >另存为
                </el-button
                >
              </el-popover>
            </template>

            <template v-else>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form
                  ref="taskPoverForm"
                  :model="formData"
                  :rules="rules"
                  label-width="100px"
                >
                  <el-form-item label="作品名称：" prop="title">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.title"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="saveScratch('1')"
                  >确 定
                  </el-button
                  >
                  <el-button type="info" size="mini" @click="taskClose"
                  >取 消
                  </el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  size="small"
                  type="primary"
                  round
                  style="margin-right: 15px"
                  :loading="isGoShow"
                >保 存
                </el-button
                >
              </el-popover>
            </template>
            <el-button
              size="small"
              type="info"
              round
              plain
              style="margin-right: 15px"
              @click="specification"
            >任务说明
            </el-button
            >
            <el-popover
              ref="taskPoverScratch"
              placement="top-end"
              width="230"
              trigger="click"
              @show="submitRecord"
            >
              <template v-if="scratchList.length === 0">
                <el-row style="text-align: center">
                  <span>暂无提交记录</span>
                </el-row>
              </template>
              <ul class="python-list record-list" v-else>
                <li v-for="item of scratchList" :key="item.id">
                  <p class="clearfix">
                    <!-- <span class="fl title-link pointer" @click="previewScratch(item)">{{item.title}}</span> -->
                    <el-tooltip
                      class="fl"
                      effect="dark"
                      :content="item.createTime.substring(0, 19)"
                      placement="top-start"
                    >
                  <span
                    class="title-link pointer"
                    @click="previewScratch(item)"
                  >{{ item.title }}</span
                  >
                    </el-tooltip>
                  </p>
                </li>
              </ul>
              <el-button
                slot="reference"
                type="info"
                style="margin-right: 15px"
                round
                plain
                size="small"
              >提交记录
              </el-button>
            </el-popover>
          </div>
        </el-row>
      </el-row>
       <el-row class="fr" v-if="taskInfo.type === 'Pygame'">
<!--        <el-button size="small" type="primary" round style="margin-right: 15px;" :loading="isGoShow">保 存</el-button>-->
<!--        <el-button size="small" type="info" round plain style="margin-right: 15px;" @click="saveScratch('2')" :loading="isGoShow">另存为</el-button>-->
        <template v-if="isChoosePygame">
          <span style="margin-right: 15px;">
            作品名称：{{pygameChoose}}
          </span>
          <el-button size="small" type="primary" round  @click="savePygame('1')" style="margin-right: 15px;" :loading="isGoShow" >保 存</el-button>
          <el-popover
                  ref="taskPover"
                  placement="top"
                  width="350"
                  trigger="click"
                  class="mr-10"
          >
            <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px" @submit.native.prevent>
              <el-form-item label="作品名称：" prop="titleBesides">
                <el-input
                        placeholder="请输入作品名称"
                        @input="forceUpdate"
                        v-model.trim="formData.titleBesides">
                </el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button type="primary" size="mini" @click="savePygame('2')">确 定</el-button>
              <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
            </div>
            <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;" :loading="isGoShow">另存为</el-button>
          </el-popover>
        </template>

        <template v-else>
          <el-popover
                  ref="taskPover"
                  placement="top"
                  width="350"
                  trigger="click"
                  class="mr-10"
          >
            <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px" @submit.native.prevent>
              <el-form-item label="作品名称：" prop="title">
                <el-input
                        placeholder="请输入作品名称"
                        v-model.trim="formData.title">
                </el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button type="primary" size="mini" @click="savePygame('1')">确 定</el-button>
              <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
            </div>
            <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;" :loading="isGoShow">保 存</el-button>
          </el-popover>
        </template>
        <el-button size="small" type="info" round plain style="margin-right: 15px;"  @click="specification">任务说明</el-button>
        <el-popover
                placement="top-end"
                width="230"
                trigger="hover"
                @show="submitPygameRecord"
        >
          <template v-if="pygameList.length===0">
            <el-row style="text-align: center;">
              <span>暂无提交记录</span>
            </el-row>
          </template>
          <ul class="python-list record-list" v-else>
            <li v-for="(item) of pygameList" :key="item.id">
              <p class="clearfix">
                <el-tooltip class="fl " effect="dark" :content="item.createTime.substring(0, 19)" placement="top-start">
                  <span class="title-link pointer" @click="previewPygame(item)">{{item.title}}</span>
                </el-tooltip>
              </p>
            </li>
          </ul>
          <el-button slot="reference" type="info" style="margin-right: 15px;" round plain size="small">提交记录</el-button>
        </el-popover>
      </el-row>

      <el-row class="fr" v-if="taskInfo.type === 'python'">
        <div style="display: flex;align-items: center;line-height: 60px;height: 60px;">
          <el-carousel ref="pythonCarousel" height="45px" style="width: 300px;border-radius: 50px;margin-right: 30px;" :autoplay="autoplay"
                       :loop="autoplay"
                       @change="pythonQuestionChange" arrow="always">
            <el-carousel-item v-for="(item,index) in pythonQuestionForTaskList" :key="index">
              <span class="small">第{{ index + 1 }}道题</span>
            </el-carousel-item>
          </el-carousel>
          <template v-if="editPython !== null">
            <span style="margin-right: 15px">
              作品名称：{{ editPython.title }}
            </span>
            <el-button
              size="small"
              type="primary"
              round
              @click="savePython"
              style="margin-right: 15px"
              :loading="isGoShow"
            >保 存
            </el-button
            >
            <el-popover
              ref="PythontaskPover"
              placement="top"
              width="350"
              trigger="click"
              class="mr-10"
            >
              <el-form
                ref="taskPoverForm"
                :model="formData"
                :rules="rules"
                label-width="100px"
              >
                <el-form-item label="作品名称：" prop="titleBesides">
                  <el-input
                    placeholder="请输入作品名称"
                    @input="forceUpdate"
                    v-model.trim="formData.titleBesides"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  type="primary"
                  size="mini"
                  @click="taskConfirm('python', 'again')"
                >确 定
                </el-button
                >
                <el-button type="info" size="mini" @click="pythonTaskClose"
                >取 消
                </el-button
                >
              </div>
              <el-button
                slot="reference"
                size="small"
                type="primary"
                round
                style="margin-right: 15px"
                :loading="isGoShow"
              >另存为
              </el-button
              >
            </el-popover>
          </template>

          <template v-else>
            <el-popover
              ref="PythontaskPover"
              placement="top"
              width="350"
              trigger="click"
              class="mr-10"
            >
              <el-form
                ref="taskPoverForm"
                :model="formData"
                :rules="rules"
                label-width="100px"
              >
                <el-form-item label="作品名称：" prop="title">
                  <el-input
                    placeholder="请输入作品名称"
                    @input="forceUpdate"
                    v-model.trim="formData.title"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  type="primary"
                  size="mini"
                  @click="taskConfirm('python')"
                >确 定
                </el-button
                >
                <el-button type="info" size="mini" @click="pythonTaskClose"
                >取 消
                </el-button
                >
              </div>
              <el-button
                slot="reference"
                size="small"
                type="primary"
                round
                style="margin-right: 15px"
                :loading="isGoShow"
              >保 存
              </el-button
              >
            </el-popover>
          </template>
          <el-popover
            placement="top-end"
            width="230"
            trigger="click"
            ref="popoverPython"
            style="margin-right: 25px"
            @show="getListPythonTaskCode"
          >
            <template v-if="pythonList.length === 0">
              <el-row style="text-align: center">
                <span>暂无提交记录</span>
              </el-row>
            </template>
            <ul class="python-list record-list" v-else>
              <li v-for="item of pythonList" :key="item.id">
                <p class="clearfix">
                  <el-tooltip
                    class="fl"
                    effect="dark"
                    :content="item.createTime.substring(0, 19)"
                    placement="top-start"
                  >
                  <span
                    class="title-link pointer"
                    @click="previewPython(item)"
                  >{{ item.title }}</span
                  >
                  </el-tooltip>
                  <span
                    v-text="pythonText(item.isCorrect)"
                    :style="'color' + ':' + pythonColor(item.isCorrect)"
                    class="fr"
                  ></span>
                </p>
              </li>
            </ul>
            <el-button slot="reference" round size="small" type="info">
              提交记录
            </el-button>
          </el-popover>
        </div>
      </el-row>

      <el-row v-if="taskInfo.type === 'goc'">
        <el-row class="fr">
          <template v-if="isShowGoc">
            <template v-if="editGoC !== null">
              <span style="margin-right: 15px">
                作品名称：{{ editGoC.title }}
              </span>
              <el-button
                size="small"
                type="primary"
                round
                @click="submitGoc"
                style="margin-right: 15px"
                :loading="isGoShow"
              >保 存
              </el-button
              >
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form
                  ref="taskPoverForm"
                  :model="formData"
                  :rules="rules"
                  label-width="100px"
                >
                  <el-form-item label="作品名称：" prop="titleBesides">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.titleBesides"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="taskConfirm('goc', 'again')"
                  >确 定
                  </el-button
                  >
                  <el-button type="info" size="mini" @click="taskClose"
                  >取 消
                  </el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  size="small"
                  type="primary"
                  round
                  style="margin-right: 15px"
                  :loading="isGoShow"
                >另存为
                </el-button
                >
              </el-popover>
            </template>

            <template v-else>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form
                  ref="taskPoverForm"
                  :model="formData"
                  :rules="rules"
                  label-width="100px"
                >
                  <el-form-item label="作品名称：" prop="title">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.title"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="taskConfirm('goc')"
                  >确 定
                  </el-button
                  >
                  <el-button type="info" size="mini" @click="taskClose"
                  >取 消
                  </el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  size="small"
                  type="primary"
                  round
                  style="margin-right: 15px"
                  :loading="isGoShow"
                >保 存
                </el-button
                >
              </el-popover>
            </template>

            <el-button
              size="small"
              type="info"
              round
              plain
              @click="specification"
              style="margin-right: 25px">
              任务说明
            </el-button>
            <el-popover
              placement="top-end"
              width="230"
              trigger="click"
              @show="getListGoCTaskCode"
              style="margin-right: 25px"
            >
              <template v-if="gocList.length === 0">
                <el-row style="text-align: center">
                  <span>暂无提交记录</span>
                </el-row>
              </template>
              <ul class="python-list record-list" v-else>
                <li v-for="item of gocList" :key="item.id">
                  <p class="clearfix">
                    <!-- <span class="fl title-link pointer" @click="previewGoC(item)">{{item.createTime.substring(0, 19)}}</span> -->
                    <el-tooltip
                      class="fl"
                      effect="dark"
                      :content="item.createTime.substring(0, 19)"
                      placement="top-start"
                    >
                      <span
                        class="title-link pointer"
                        @click="previewGoC(item)"
                      >{{ item.title }}</span
                      >
                    </el-tooltip>
                  </p>
                </li>
              </ul>
              <el-button slot="reference" round size="small" type="info"
              >提交记录
              </el-button
              >
            </el-popover>
          </template>
        </el-row>
      </el-row>
    </el-header>
    <!-- taskInfo.sourceContent：{{taskInfo.sourceContent}} -->
    <!-- <el-main :class="[taskInfo.type === '编程'?'bc-main':'main']"> -->
    <!-- taskInfo：{{taskInfo}}
    <hr>
    gocSourceContent: {{gocSourceContent}}
    <hr>
    taskPremise：{{taskPremise}} -->
    <el-main :class="getStateTaskInfo(taskInfo.type)">
      <template v-if="taskInfo.type === '图文'">
        <!-- taskInfo.sourceContent：{{taskInfo.sourceContent}} -->
        <el-row class="content">
          <el-col :span="20">
            <div v-html="taskInfo.sourceContent"></div>
          </el-col>
          <!-- <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></!-->
        </el-row>
      </template>
      <template v-else-if="taskInfo.type === '上传'">
        <upload-file
          @setFinishTime="setFinishTime"
          :taskInfo="taskInfo"
        ></upload-file>
      </template>
      <template
        v-else-if="taskInfo.type === '外链' || taskInfo.type === '补课视频' || taskInfo.type === 'ppt'"
      >
        <iframe-box :taskInfo="taskInfo"></iframe-box>
      </template>
      <template v-else-if="taskInfo.type === '视频'">
        <!-- <iframe-box :taskInfo="taskInfo"></iframe-box> -->
        <videoBox :sourceVideoIds='sourceVideoId' roles='management' :heights="60"/>

      </template>
      <template v-else-if="taskInfo.type === 'python'">
        <iframe
          :src="taskInfo.sourceContent"
          frameborder="1"
          width="100%"
          allowfullscreen="true"
          ref="leftFrame"
          id="leftFrame"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-else-if="taskInfo.type === 'pdf'">
        <student-pdf :taskID="taskID"></student-pdf>
      </template>
      <template v-else-if="taskInfo.type === '编程'">
        <student-split
          :contest_id="contest_id"
          :taskID="taskID"
          :taskInfo="taskInfo"
          @getTaskFinish="getTaskFinish"
          @getSplitpaneCode="getSplitpaneCode"
          @nextTask="nextTask"
          @updateView="taskGetInfo"
          ref="stuQuestion"
        ></student-split>
      </template>
      <template v-else-if="taskInfo.type === '测试'">
        <template
          v-if="
            examStatus === '未考试' ||
            examStatus === '未交卷' ||
            examStatus === '再次尝试'
          "
        >
          <exam-begin
            :sourceId="taskInfo.sourceId"
            :examBeginTime="examBeginTime"
            :taskID="taskID"
            @checkResult="checkResult"
          ></exam-begin>
        </template>
        <template v-else-if="examStatus === '未公布'">
          <exam-result :isReview="false" :taskID="taskID"></exam-result>
        </template>
        <template v-else-if="examStatus === '已公布'">
          <exam-result
            :isReview="true"
            :taskID="taskID"
            @SubPaperAgain="SubPaperAgain"
          ></exam-result>
        </template>
      </template>

      <template v-else-if="taskInfo.type === 'goc'">
        <iframe
          :src="goSrc"
          frameborder="1"
          width="100%"
          allowfullscreen="true"
          ref="gocFrame"
          id="gocFrame"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-else-if="taskInfo.type === 'scratch'">
        <iframe
          ref="scratchFrame"
          :src="scratchIde"
          frameborder="1"
          allowfullscreen="true"
          width="100%"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        >
        </iframe>
      </template>
      <template v-else-if="taskInfo.type === 'Pygame'">
        <PygamePreLook @previewPygame="previewPygame" @taskFinish="taskFinish" ref="pygameFrame" :taskInfo="taskInfo"/>
      </template>
    </el-main>

    <task-specification
      :show="specificationDialog"
      @close="specificationDialog = false"
      :taskInfo="taskInfo"
    ></task-specification>

    <el-drawer
      title="任务3目录"
      :visible.sync="drawerDrawer"
      direction="rtl"
      size="30%"
      :before-close="() => (drawerDrawer = false)"
    >
      <div class="catogory">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            :name="(index+1)"
            v-for="(item, index) in courseDetails"
            :key="index"
          >
            <template slot="title">
                    <span style="font-size: 16px">
                      第{{ index + 1 }}课
                      <span style="margin-left: 10px">
                        {{ item.title }}
                      </span>
                    </span>
            </template>
            <!-- <div v-for="(i, j) in item.taskList" :key="j" class="catogory-item"> -->
            <!-- <el-row style="text-align: center">
              <el-col :span="8">任务{{ j + 1 }}:</el-col>
              <el-col :span="8">{{ i.title }}</el-col>
              <el-col :span="8">
                {{i.finishiTime}}
                {{ i.finishiTime ? "已完成" : "未完成" }}
              </el-col>
            </el-row> -->
            <ul
              v-for="(activity, indexs) in item.taskList"
              :key="indexs"
              class="task-list"
            >
              <template v-if="activity.status">
                <li class="task-item">
                    <span class="task-tit" @click="previewTask(activity)">
                      <span class="task-dy">任务{{ indexs + 1 }}</span>
                      {{ activity.title }}
                    </span>
                  <span
                    class="task-right"
                    v-if="!activity.startTime"
                    style="color: #c2c2c2"
                  >未学习</span
                  >
                  <span
                    class="task-right"
                    v-else-if="activity.startTime && !activity.finishTime"
                    style="color: #f5c319"
                  >已学习</span
                  >
                  <span class="task-right" v-else style="color: #90dc61"
                  >已完成</span
                  >
                </li>
              </template>
              <template v-else>
                <li class="task-item">
                    <span class="task-tit" style="cursor: default">
                      <span class="task-dy">任务{{ indexs + 1 }}</span>
                      {{ activity.title }}
                    </span>
                  <!-- <span class="task-type">{{activity.type}}</span> -->
                  <span class="task-right" style="color: #c2c2c2"
                  >敬请期待</span
                  >
                </li>
              </template>
            </ul>
            <!-- </div> -->
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- <div v-for="(item,index) in courseDetails" :key="index" class="catogory">
      <h1 class="catogory-title">{{item.title}}</h1>
      <div v-for="(i,j) in item.taskList" :key="j" class="catogory-item">
        <el-row style="text-align:center">
          <el-col :span="8">任务{{j+1}}:</el-col>
          <el-col :span="8">{{i.title}}</el-col>
          <el-col :span="8">
            {{i.finishiTime?'已完成':'未完成'}}
          </el-col>
        </el-row>

      </div>
    </div> -->
    </el-drawer>
  </el-container>
</template>

<script>
import { showLoading, hideLoading } from '@/utils/loading'
import studentQuestion from './components/studentQuestion'
import studentPdf from './components/pdfpreview'
import uploadFile from './components/uploadfile'
import studentSplit from './components/splitpane'
import examBegin from './components/examBegin'
import examResult from './components/examResult'
import IframeBox from './components/iframeBox'
import { formatTate } from '@/utils/formatDate'
import taskSpecification from '@/components/task-specification'
import Prism from 'prismjs' //引入插件
import videoBox from '@/views/detail/video-detail'
import $axios from '@/utils/request'
import AgoraRTM from 'agora-rtm-sdk'
import { getCourseUnit } from '@/api/businesscourse/train'
import {
  getListQuestionforTask,
} from '@/apiteacher/teacher'
import { getToken } from '@/utils/auth'
  import PygamePreLook from '@/views/Pygame/taskpre';

export default {
  name: 'previewTask',
  components: {
    studentQuestion,
    studentPdf,
    examBegin,
    examResult,
    IframeBox,
    studentSplit,
    uploadFile,
    taskSpecification,
    videoBox,
    PygamePreLook,
  },
  watch: {
    endDate: {
      handler (newVal, oldVal) {
        if (this.isEnd) {
          setTimeout(() => {
            this.endDate = new Date()
            if (
              this.taskPremise == minutedifference(this.startDate, this.endDate)
            ) {
              this.isEnd = false
              this.taskFinishTime()
            }
          }, 1000)
        }
      },
    },
  },
  data () {
    return {
      sourceVideoId: '',
      scratchIde: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ATTRS + '?timeThemb=' + new Date().getTime() : process.env.VUE_APP_PREVIEW_ADDRESS + process.env.VUE_APP_PREVIEW_ATTR + '?timeThemb=' + new Date().getTime(),// scratch服务器地址
      activeName: '1',
      drawerDrawer: false, //目录抽屉
      taskTitle: '详情页面',
      taskID: '',
      taskInfo: {},
      contest_id: '', // 题库ID
      startDate: new Date(), // 任务开始时间
      endDate: null, // 任务结束时间
      isEnd: false,
      taskPremise: null,
      examStatus: '', // 试卷的状态
      paper_id: '', // 试卷的ID
      lessonId: '', // 课程ID
      examBeginTime: '', // 试卷开始时间
      pythonList: [], // python 提交记录
      scratchList: [], // scratch 提交记录
      pygameList: [], // pygame 提交记录
      gocList: [], // goc提交记录
      goSrc: '', // goc任务外链
      gocPath: process.env.VUE_APP_GOC_ADDRESS,
      specificationDialog: false,
      isShowGoc: false,
      gocSourceContent: null,
      isGoShow: false,
      editGoC: null,
      editPython: null,
      submitAgainGocState: false,
      submitAgainPythonState: false,
      scratchChoose: '',
      pygameChoose: '',
      scratchCreateTime: '',
      formData: {},
      formDatas: {},
      updateCode: false,
      isBesides: false,
      minHistoryTime: '',
      rules: {
        title: [{ required: true, message: '请填写作品名称', trigger: 'blur' }],
        titleBesides: [
          { required: true, message: '请填写作品名称', trigger: 'blur' },
        ],
      },
      isChooseScratch: false,
      isChoosePygame: false,
      globalClient: null,
      globalChannelTask: null,
      rtmToken: null,
      rtmappid: null,
      courseType: '',
      timer: null,
      currentLessonId: '',
      currentteacherLoginName: '',
      courseDetails: [], // 课节信息
      currentBatch: '',
      pythonQuestionForTaskList: [],
      scratchQuestionForTaskList: [],
      pygameQuestionForTaskList: [],
      autoplay: false,
    }
  },
  created () {
    this.isChooseScratch = false
    this.scratchChoose = ''
    this.scratchCreateTime = ''
    this.isChoosePygame = false
    this.pygameChoose = ''
    this.pygameCreateTime = ''
    this.taskID = this.$route.query.id
    this.minHistoryTime = this.$route.query.minHistoryTime
    this.lessonId = this.$route.query.lessonId
    this.currentLessonId = this.$route.query.currentLessonId
    this.currentteacherLoginName = this.$route.query.teacherLoginName

    this.courseType = this.$route.query.type
    if (!this.taskID) {
      this.videoUrl = this.$route.query.url
      this.taskInfo.type = '补课视频'
      this.taskInfo.sourceContent = this.videoUrl
    } else {
      this.taskStart()
      this.taskGetInfo()
      this.endDate = new Date()
      this.isEnd = true
    }
  },
  mounted () {
    let _this = this
    window.addEventListener(
      'message',
      function (msg) {
        if (msg.data.setState === 'true') {
          console.log(msg.data)
          if (msg.data.typeCode === 'python') {
            _this.submitPythonCode(msg.data.setCode)
          } else {
            _this.submitCode(msg.data.setCode)
          }
        }
      },
      false
    )
  },
  methods: {
    // 加载预知代码
      previewPygame(row) {
        this.isChoosePygame = true
        this.pygameChoose = row.title
        this.pygameCreateTime = row.createTime.substring(0,19)
        let courseTask={
            title: row.title,
            taskId: this.taskID,
            courseId: this.lessonId
        }
        this.$refs.pygameFrame.initPygameProject(courseTask,row);
        // this.taskFinish()
        $axios
        .fPost("/student/task/getInfo", {
          id: this.taskID
        }).then(res => {
          if (res.state === "success") {
            this.taskInfo = { ...res.body };
          }
        })
      },
    scratchQuestionChange (value) {
      this.$refs.taskPoverScratch.doClose()
      this.isChooseScratch = false
      this.scratchCreateTime = this.scratchQuestionForTaskList[value].createTime.substring(0, 19)
      this.currentBatch = this.scratchQuestionForTaskList[value].batch
      let data = {
        index: value
      }
      this.$refs.scratchFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: this.scratchQuestionForTaskList[value].sourceScratchTemplate.file,
        taskId: this.taskInfo.id,
        batch: this.scratchQuestionForTaskList[value].batch,
        token: localStorage.getItem('token_business'),
        fileName: this.scratchQuestionForTaskList[value].sourceScratchTemplate.title
      }, '*')
    },
    pygameQuestionChange (value) {
      this.$refs.taskPoverPygame.doClose()
      this.isChoosePygame = false
      this.pygameCreateTime = this.pygameQuestionForTaskList[value].createTime.substring(0, 19)
      this.currentBatch = this.pygameQuestionForTaskList[value].batch
      let data = {
        index: value
      }
      this.$refs.pygameFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: this.pygameQuestionForTaskList[value].sourceScratchTemplate.file,
        taskId: this.taskInfo.id,
        batch: this.pygameQuestionForTaskList[value].batch,
        token: localStorage.getItem('token_business'),
        fileName: this.pygameQuestionForTaskList[value].sourceScratchTemplate.title
      }, '*')
    },
    submitPygameRecord() {
        $axios
        .fPost("/student/course/creation/getListByUser", {
          taskId: this.taskID,
          pageNum: 1,
          pageSize: 10000,
        }).then(res => {
          if (res.state === 'success') {
            this.pygameList = res.body;
          } else {
            this.$message.info("请求失败");
          }
        }).catch(err => {
            this.$message.info("请求失败");
        });
      },
    pythonQuestionChange (value) {
      this.$refs.popoverPython.doClose()
      this.editPython = null
      let code = this.pythonQuestionForTaskList[value].content
      this.currentBatch = this.pythonQuestionForTaskList[value].batch
      this.$refs.leftFrame.contentWindow.location = this.taskInfo.sourceContent
      setTimeout(() => {
        let data = {
          index: value
        }
        this.$refs.leftFrame.contentWindow.postMessage({
          code: code
        }, '*')
      }, 1000)
    },
    async getListQuestionforTask (type) {
      const res = await getListQuestionforTask({
        taskId: this.taskID
      })
      switch (type) {
        case 'python':
          this.pythonQuestionForTaskList = res.body
          this.$nextTick(() => {
            this.$refs.pythonCarousel.setActiveItem(0)
          })
          if (this.pythonQuestionForTaskList.length !== 0) {
            let code = this.pythonQuestionForTaskList[0].content
            this.currentBatch = this.pythonQuestionForTaskList[0].batch
            this.taskInfo.sourceContent = process.env.VUE_APP_PYTHON_IDE + '?pagetype=teacher&pagesource=preview'
            setTimeout(() => {
              this.$refs.leftFrame.contentWindow.postMessage({
                code: code
              }, '*')
            }, 1000)
          }
          break
        case 'scratch':
          this.scratchQuestionForTaskList = res.body
          this.$refs.scratchCarousel.setActiveItem(0)
          this.currentBatch = this.scratchQuestionForTaskList[0].batch
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.scratchFrame.contentWindow.postMessage({
                pageType: 'studentrecord',
                filePath: this.scratchQuestionForTaskList[0].sourceScratchTemplate.file,
                taskId: this.taskInfo.id,
                batch: this.currentBatch,
                token: localStorage.getItem('token_business'),
                fileName: this.scratchQuestionForTaskList[0].sourceScratchTemplate.title
              }, '*')
            })
          }, 1000)
          break
      }

    },
    // 目录
    taskListHandler () {
      this.drawerDrawer = true
      getCourseUnit({
        pageNum: 1,
        pageSize: 1000,
        courseId: this.lessonId,
      })
        .then((res) => {
          if (res.state === 'success') {
            this.courseDetails = res.body.list
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败', 1)
        })
    },
    getSplitpaneCode (data) {
      let that = this
      let codeObject = {
        type: 'sendCurrentCode',
        language: 'C++',
        taskId: this.taskID,
        code: data.code,
      }
      this.globalChannel
        .sendMessage({ text: JSON.stringify(codeObject) })
        .then(() => {
          /* 频道消息发送成功的处理逻辑 */
          console.log('sendMesssage=======>success')
        })
        .catch((error) => {
          console.log('sendMesssage=======>fail')
          /* 频道消息发送失败的处理逻辑 */
        })
    },
    //向tms端发送code
    setLocalCode () {
      this.$refs.stuQuestion.setCodeToparent()
    },
    arryGroupMatch (arr) {
      let map = {}
      for (let i = 0; i < arr.length; i++) {
        let ai = arr[i]
        if (!map[ai.group]) {
          map[ai.group] = [ai]
        } else {
          map[ai.group].push(ai)
        }
      }
      let res = []
      Object.keys(map).forEach((key) => {
        res.push({
          title: key,
          data: map[key],
        })
      })
      return res
    },
    taskConfirm (state, type) {
      this.$refs.taskPoverForm.validate((valid) => {
        if (valid) {
          if (type === 'again') {
            console.log('isBesides show')
            this.isBesides = true
            this.updateCode = false
          }
          switch (state) {
            case 'goc':
              return setTimeout(() => {
                this.$refs.gocFrame.contentWindow.postMessage(
                  {
                    getCode: 'true',
                    type: 'tms',
                  },
                  '*'
                )
              }, 0)
            case 'python':
              this.pythonTaskClose()
              return setTimeout(() => {
                this.$refs.leftFrame.contentWindow.postMessage(
                  {
                    getCode: 'true',
                  },
                  '*'
                )
              }, 0)
          }
        }
      })
    },

    forceUpdate () {
      this.$forceUpdate()
    },
    pythonTaskClose() {
      this.$refs.PythontaskPover.doClose()
    },
    taskClose () {
      this.$refs['taskPover'].doClose()
    },

    previewScratch (row) {
      this.isChooseScratch = true
      this.scratchChoose = row.title
      this.scratchCreateTime = row.createTime.substring(0, 19)
      showLoading()
      this.$refs.scratchFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: row.file,
        batch: this.currentBatch,
        taskId: row.taskId,
        token: localStorage.getItem('token_business'),
        fileName: row.title
      }, '*')
    },
    previewPygame (row) {
      this.isChoosePygame = true
      this.pygameChoose = row.title
      this.pygameCreateTime = row.createTime.substring(0, 19)
      showLoading()
      this.$refs.pygameFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: row.file,
        batch: this.currentBatch,
        taskId: row.taskId,
        token: localStorage.getItem('token_business'),
        fileName: row.title
      }, '*')
    },
    savePygame(flag) {
        let thembFileName = ""
        if(flag === "2") {
          if(!this.formData.titleBesides) {
            window.$msg('请填写作品名称', 2)
            return false
          }else {
            thembFileName = this.formData.titleBesides.indexOf(".json") !== -1 ? this.formData.titleBesides : this.formData.titleBesides+".json"
          }
        }else if(flag === "1") {
          if(this.isChoosePygame) {
            thembFileName = this.pygameChoose
          } else if(!this.isChoosePygame && !this.formData.title ) {
            window.$msg('请填写作品名称', 2)
            return false
          } else if(!this.isChoosePygame && this.formData.title ) {
            thembFileName = this.formData.title.indexOf(".json") !== -1 ? this.formData.title : this.formData.title+".json"
          }
        }

        let thembpageType = ""
        if(flag === "2") {
          thembpageType = "studentsaveAsPygame"
        }else if(flag === "1") {
          thembpageType = "studentsavePygame"
          if(!this.isChoosePygame) {
            this.pygameCreateTime = this.getCurrent()
          }
        }

        this.taskClose()
        if(!this.isChoosePygame) {
          this.formData.title = ""
        }else {
          this.formData.titleBesides = ""
        }
        if(thembFileName){
          let courseTask={
            title: thembFileName,
            name: this.formData.title,
            flag: this.scratchJrChoose,
            taskId: this.taskID,
            courseId: this.lessonId,
            saveType: flag =='1'?'save':'other'
          }
          this.$refs.pygameFrame.saveFile({...courseTask,info:this.taskInfo});
        }
      },
    saveScratch (flag) {
      let thembFileName = ''
      if (flag === '2') {
        if (!this.formData.titleBesides) {
          window.$msg('请填写作品名称', 2)
          return false
        } else {
          thembFileName =
            this.formData.titleBesides.indexOf('.sb3') !== -1
              ? this.formData.titleBesides
              : this.formData.titleBesides + '.sb3'
        }
      } else if (flag === '1') {
        if (this.isChooseScratch) {
          thembFileName = this.scratchChoose
        } else if (!this.isChooseScratch && !this.formData.title) {
          window.$msg('请填写作品名称', 2)
          return false
        } else if (!this.isChooseScratch && this.formData.title) {
          thembFileName =
            this.formData.title.indexOf('.sb3') !== -1
              ? this.formData.title
              : this.formData.title + '.sb3'
        }
      }

      let thembpageType = ''
      if (flag === '2') {
        thembpageType = 'studentsaveAsScratch'
      } else if (flag === '1') {
        thembpageType = 'studentsaveScratch'
        if (!this.isChooseScratch) {
          this.scratchCreateTime = this.getCurrent()
        }
      }

      if (!this.isChooseScratch) {
        this.formData.title = ''
      } else {
        this.formData.titleBesides = ''
      }
      showLoading()
      setTimeout(() => {
        this.$refs.scratchFrame.contentWindow.postMessage(
          {
            pageType: 'studentsave',
            taskId: this.taskID,
            batch: this.currentBatch,
            token: localStorage.getItem('token_business'),
            fileName: thembFileName,
            scratchCreatTime:
              thembpageType === 'studentsaveScratch'
                ? this.scratchCreateTime
                : this.getCurrent(),
          },
          '*'
        )
      }, 1500)
    },

    savePython () {
      this.updateCode = true
      this.$refs.leftFrame.contentWindow.postMessage(
        {
          getCode: 'true',
        },
        '*'
      )
    },

    submitRecord () {
      // 请求学生scratch提交记录
      $axios
        .fPost('/meredu/task/taskScratchList', {
          taskId: this.taskID,
          batch: this.currentBatch,
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.scratchList = res.body.list
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },
    submitRecordPygame () {
      // 请求学生scratch提交记录
      $axios
        .fPost('/meredu/task/taskPygameList', {
          taskId: this.taskID,
          batch: this.currentBatch,
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.pygameList = res.body.list
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },
    pythonText (state) {
      switch (state) {
        case true:
          return '正确'
        case false:
          return '错误'
        case null:
          return '未批阅'
        default:
          return '系统未知状态'
      }
    },
    pythonColor (state) {
      switch (state) {
        case true:
          return '#4DA54D'
        case false:
          return '#f56c6c'
        case null:
          return '#909399'
        default:
          return '#666'
      }
    },
    setFinishTime () {
      this.taskInfo.finishTime = true
    },
    goBack () {
      this.$router.push({
        // 跳转到课程目录页面
        path: '/meredu/curriculumCatalog',
        query: {
          id: this.lessonId,
        },
      })
    },

    submitPythonCode (code) {
      // 提交python代码
      if (!code) {
        window.$msg('代码不能为空', 2)
      } else {
        var title = ''
        if (this.isBesides) {
          title = this.formData.titleBesides
        } else {
          title = this.formData.title
        }
        $axios
          .fPost('/meredu/task/submitPythonTask', {
            token: getToken(),
            taskId: this.taskID,
            batch: this.currentBatch,
            code: code,
            title: Boolean(this.updateCode) ? this.editPython.title : title,
            createTime:
              Boolean(this.editPython) &&
              Boolean(!this.submitAgainGocState) &&
              Boolean(!this.isBesides)
                ? this.editPython.createTime
                : null,
          })
          .then((res) => {
            if (res.state === 'success') {
              window.$msg('代码提交成功')
              // this.taskGetInfo()
              this.editPython = res.body
              if (this.isBesides) {
                this.formData.titleBesides = ''
              } else {
                this.formData.title = ''
              }
              this.isBesides = false
            } else {
              $msg(res.errMsg, 2)
            }
          })
          .catch((err) => {
            $msg('接口请求失败', 1)
            console.log('err', err)
          })
      }
    },

    submitCode (code) {
      // 提交goc代码
      if (!code) {
        window.$msg('代码不能为空', 2)
      } else {
        var title = ''
        if (this.isBesides) {
          title = this.formData.titleBesides
        } else {
          title = this.formData.title
        }
        $axios
          .fPost('/meredu/task/submitGoc', {
            taskId: this.taskID,
            code: code,
            title: Boolean(this.updateCode) ? this.editGoC.title : title,
            createTime:
              Boolean(this.editGoC) &&
              Boolean(!this.submitAgainGocState) &&
              Boolean(!this.isBesides)
                ? this.editGoC.createTime
                : null,
          })
          .then((res) => {
            if (res.state === 'success') {
              this.$refs['taskPover'].doClose()
              window.$msg('代码提交成功')
              this.editGoC = res.body
              if (this.isBesides) {
                this.formData.titleBesides = ''
              } else {
                this.formData.title = ''
              }
              this.isBesides = false
              // this.taskGetInfo()
            } else {
              $msg(res.errMsg, 2)
            }
          })
          .catch((err) => {
            $msg('接口请求失败', 1)
            console.log('err', err)
          })
      }
    },

    submitGoc () {
      // 提交goc代码
      this.updateCode = true
      this.isGoShow = true
      setTimeout(() => {
        this.isGoShow = false
      }, 3000)
      setTimeout(() => {
        this.$refs.gocFrame.contentWindow.postMessage(
          {
            getCode: 'true',
            type: 'tms',
          },
          '*'
        )
      }, 0)
    },

    sunmitAgainGoc () {
      if (!this.formData.title) {
        window.$msg('请填写作品名称', 2)
      } else {
        this.submitAgainGocState = true
        this.isGoShow = true
        setTimeout(() => {
          this.isGoShow = false
        }, 3000)
        setTimeout(() => {
          this.$refs.gocFrame.contentWindow.postMessage(
            {
              getCode: 'true',
              type: 'tms',
            },
            '*'
          )
        }, 0)
        // if (this.formData.title === this.editGoC.title) {
        //   window.$msg('另存为请修改作品名称后再提交', 1)
        // } else {

        // }
      }
    },

    getListGoCTaskCode () {
      // 请求学生提交记录
      $axios
        .fGet('/meredu/task/gocList', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.gocList = res.body
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },

    specification () {
      this.specificationDialog = true
    },

    getListPythonTaskCode () {
      // 请求学生提交记录
      $axios
        .fPost('/meredu/task/listPythonTaskCode', {
          taskId: this.taskID,
          batch: this.currentBatch,
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.pythonList = res.body.list
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },

    taskGetInfo () {
      let _this = this
      $axios
        .fPost('/meredu/task/getInfo', {
          id: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.taskInfo = { ...res.body }
            this.taskInfo.minHistoryTime = this.minHistoryTime
              ? this.minHistoryTime.substring(0, this.minHistoryTime.length - 2)
              : ''
            if (res.body.type === 'python') {
              this.getListQuestionforTask('python')
            }
            if (
              res.body.type === '图文' ||
              res.body.type === '外链' ||
              res.body.type === 'pdf'
            ) {
              this.taskPremise = res.body.completeCondition
            } else if (res.body.type === 'ppt') {
              this.taskPremise = res.body.completeCondition
              this.taskInfo.sourceContent = process.env.VUE_APP_PREVIEW_ADDRESS + this.taskInfo.sourceContent
            } else if (res.body.type === '编程') {
              this.contest_id = res.body.sourceId
            } else if (res.body.type === '测试') {
              this.paper_id = res.body.sourceId
              this.getExamStatus()
            } else if (res.body.type === '视频') {
              this.taskPremise = res.body.completeCondition
              // this.getVideoUrl(res.body.sourceId);
              this.sourceVideoId = res.body.sourceId
            } else if (res.body.type === 'goc') {
              let goObj = JSON.parse(res.body.sourceContent)
              this.gocSourceContent = goObj
              let obj = {
                mode: 5,
                win: '010',
                loop: 1,
                time: 3000,
                title: 'GoC单机版本V1.2.5',
                path: '',
                cin: '',
              }
              if (goObj.type === '代码') {
                this.isShowGoc = true
                this.goSrc = process.env.VUE_APP_GOC_ADDRESS
                setTimeout(() => {
                  this.$refs.gocFrame.contentWindow.postMessage(
                    {
                      code: goObj.code,
                      type: 'tms',
                    },
                    '*'
                  )
                }, 1000)
              } else {
                this.taskPremise = res.body.completeCondition
                this.isShowGoc = false
                this.goSrc = `${
                  process.env.VUE_APP_GOC_ADDRESS
                }?key=1&obj=${escape(JSON.stringify(obj))}&code=${escape(
                  escape(goObj.code)
                )}`
                // console.log('goSrc', this.goSrc)
              }
            } else if (res.body.type === 'scratch') {
              this.getListQuestionforTask('scratch')
              window.addEventListener('message', function (msg) {
                if (msg.data === 'pageLoadOver') {
                  hideLoading()
                }
                if (msg.data.pageSource === 'successState') {
                  console.log(msg.data)
                  _this.isChooseScratch = true
                  _this.scratchChoose = msg.data.body.title
                  _this.scratchCreateTime = msg.data.body.createTime
                  hideLoading()
                  _this.setFinishTime()
                  window.$msg('文件保存成功')
                } else if (msg.data.pageSource === 'failState') {
                  window.$msg('文件保存失败')
                }
              })
            }

            this.$nextTick(() => {
              setTimeout(() => {
                Prism.highlightAll()
              }, 0)
            })
          } else {
            $msg(res.errMsg, 2)
          }
        })
    },

    getVideoUrl (id) {
      fVideoGet('/video/getBjyUrl', {
        fileId: id,
      })
        .then((res) => {
          if (res.code === 0) {
            // let routeData = this.$router.resolve({
            //   path: "/meredu/videoPlay/",
            //   query: {
            //     url: res.data,
            //     title: title
            //   }
            // });
            this.taskInfo.sourceContent = res.data
            // window.open(routeData.href, "_blank");
          } else {
            $msg(res.msg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败', 1)
        })
    },

    // 预览提交的GoC代码
    previewGoC (item) {
      this.editGoC = item
      this.formData.title = item.title
      console.log('previewGoC', item)
      this.goSrc = process.env.VUE_APP_GOC_ADDRESS
      setTimeout(() => {
        this.$refs.gocFrame.contentWindow.postMessage(
          {
            code: item.code,
            type: 'tms',
          },
          '*'
        )
      }, 1000)
    },

    // 预览提交python任务代码
    previewPython (item) {
      this.editPython = item
      this.formData.title = item.title

      let code = item.code
      code.replace(/↵/g, '<br>')
      code = code.replace(/\r\n/g, '<br>')
      code = code.replace(/\n/g, '<br>')
      code = code.replace(/(\r\n)|(\n)/g, '<br>')
      //替换所有的空格（中文空格、英文空格都会被替换）

      this.taskInfo.sourceContent =
        process.env.VUE_APP_PYTHON_IDE +
        '?taskId=' +
        this.taskInfo.id +
        '&token=' +
        localStorage.getItem('token_business') +
        '&pagesource=preview'

      this.$refs.leftFrame.contentWindow.location = this.taskInfo.sourceContent

      setTimeout(() => {
        this.$refs.leftFrame.contentWindow.postMessage(
          {
            code: code,
          },
          '*'
        )
      }, 1000)
      this.$refs.popoverPython.doClose()
    },
    // 跳转--任务页面
    previewTask (activity) {
      // if(activity.type === "视频") {
      // this.queryVideo(activity.sourceId,activity.title)
      // }else{
      console.log(activity.pageRtmType)
      console.log(activity.courseType)
      let routeData = null
      routeData = this.$router.resolve({
        path: '/meredu/previewTask/',
        query: {
          id: activity.id,
          lessonId: this.lessonId,
          videoEnable: activity.videoSwitch,
          type: this.courseType,
          minHistoryTime: this.minHistoryTime,
          currentLessonId: this.currentLessonId,
          teacherLoginName: this.currentteacherLoginName,
        },
      })
      window.open(routeData.href, '_blank')
      // }
    },

    // 下一个可用任务
    nextTask () {
      $axios
        .fPost('/meredu/task/next', {
          taskId: this.taskID,
          courseId: this.lessonId,
        })
        .then((res) => {
          if (res.state === 'success') {
            if (res.body !== null) {
              this.$router.push({
                path: '/meredu/previewTask/',
                query: {
                  id: res.body,
                  lessonId: this.lessonId,
                  videoEnable: this.$route.query.videoEnable,
                  type: this.$route.query.type,
                },
              })
              this.$router.go(0) // 重载当前页面
            } else {
              $msg('已是最后一个开放任务', 2)
              // if (!this.$route.query.ifActivity) {
              //   setTimeout(() => {
              //     this.$router.push({
              //       name: "curriculumCatalog",
              //       query: {
              //         id: this.lessonId,
              //       },
              //     });
              //   }, 2000);
              // } else {
              // }
            }
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },

    // python 提交记录
    pythonRecord () {
    },

    // 任务开始
    taskStart (num) {
      $axios
        .fPost('/meredu/task/start', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            // if (num === 'first') {
            //   this.getExamStatus()
            // }
          } else {
            $msg(res.errMsg, 2)
          }
        })
    },

    // 获取考试状态
    getExamStatus (msg) {
      $axios
        .fPost('/meredu/exam/getStatus', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            this.examStatus = res.body.status
            if (!msg) {
              this.examBeginTime = res.body.startTime
              if (!this.examBeginTime) {
                this.getOtherNow()
              }
            } else {
              this.getOtherNow()
              // this.examBeginTime = formatTate(new Date())
            }
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('接口请求失败', 1)
          console.log('err', err)
        })
    },
    // 当前系统时间
    getCurrent () {
      var date = new Date()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentDate =
        date.getFullYear() +
        '-' +
        month +
        '-' +
        strDate +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
      return currentDate
    },
    // 获取系统当前时间
    getOtherNow () {
      $axios.fPost('/meredu/other/now').then((res) => {
        const systemTime = formatTate(new Date(res.body))
        this.examBeginTime = systemTime
      })
      // const systemTime = formatTate(new Date());
      // this.examBeginTime = systemTime
    },

    // 手动点击完成任务
    taskFinish () {
      this.getTaskFinish()
    },

    // 完成任务接口
    getTaskFinish () {
      $axios
        .fPost('/meredu/task/finish', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            $msg('恭喜你完成任务')
            this.nextTask()
          } else {
            $msg(res.errMsg, 2)
          }
        })
    },

    // 浏览时间达到要求——自动完成任务
    taskFinishTime () {
      $axios
        .fPost('/meredu/task/finish', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
          } else {
            $msg(res.errMsg, 2)
          }
        })
    },

    // 再次尝试-答题
    SubPaperAgain () {
      this.examStart()
      // this.examStatus = "再次尝试";
    },

    examStart () {
      $axios
        .fPost('/meredu/exam/start', {
          taskId: this.taskID,
        })
        .then((res) => {
          if (res.state === 'success') {
            // $msg("试卷开始计时");
            this.getExamStatus('再次尝试')
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败', 1)
          console.log('err', err)
        })
    },

    // 查看考试结果
    checkResult () {
      this.taskGetInfo()
    },

    getStateTaskInfo (state) {
      switch (state) {
        case '测试':
          return 'test-main'
        case '编程':
          return 'bc-main'
        default:
          return 'main'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.catogory {
  padding: 0 10px;
  margin-bottom: 20px;

  &-title {
    // border-top: 1px solid #eee;
  }

  &-item {
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
  }

  &-item:last-child {
    border-bottom: none;
  }
}

.container {
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
    padding: 0;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: justify;
      color: #616161;
    }
  }

  .bc-main {
    background: #fff;
    width: 100%;
    min-width: 1200px;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  .test-main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }
}

/deep/ .el-page-header__left:hover {
  color: #f5c319;
}

.footer-condition {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: #c1c1c1;

  &:hover {
    color: #f5c319;
    cursor: pointer;
  }

  .el-icon-question {
    display: inline-block;
    margin-right: 5px;
  }

  .condition-con {
    p {
      margin-bottom: 10px;
    }
  }
}

.footer-btn {
  margin-right: 25px;
  color: #212121;
}

.python-list {
  margin: 0;
  padding: 5px 8px;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.el-carousel__item span {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  padding-bottom: 5px;
  line-height: 45px;
  margin: 0;
}

.el-carousel__item:nth-child(n) {
  background-color: #99a9bf;
}

/deep/ .el-carousel__indicator--horizontal {
  display: none;
}

.record-list {
  max-height: 500px;
  overflow: auto;
}

.task-list {
  width: 100%;
  box-sizing: border-box;
  padding-left: 35px;

  .task-item {
    position: relative;
    padding: 15px 0;
    font-size: 15px;
    color: #696969;
    border-bottom: 1px solid #ebebeb;
    padding-left: 10px;

    .task-tit {
      cursor: pointer;

      &:hover {
        color: #f4cf13;

        .task-dy {
          background: #f4cf13;
          color: #fff;
        }
      }

      .task-dy {
        font-size: 12px;
        display: inline-block;
        padding: 1px 10px;
        background: #eeeeee;
        border-radius: 15px;
        margin-right: 20px;
      }
    }

    .task-type {
      font-size: 12px;
      display: inline-block;
      padding: 1px 20px;
      border-radius: 15px;
      margin-left: 35px;
      color: #fff;
      background-color: rgba(128, 0, 128, 1);
    }

    .task-right {
      float: right;
      margin-right: 25px;
    }
  }
}
</style>

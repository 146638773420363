<template>
  <div class="task-con">
    <template v-if="isReview">
      <!-- currentSub：{{currentSub}} -->
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
          <div class="panel">
            <span v-text="testTask.title" class="task-tit"></span>
            <el-row class="mt15">
              <span class="ml10">
                交卷时间：
                <span>{{testTask.examUser.finishTime}}</span>
              </span>
              <span class="ml10">
                用时：
                <span
                  v-text="changeMinutes(this.testTask.examUser.createTime, this.testTask.examUser.finishTime)"
                ></span>
              </span>
            </el-row>
            <el-row class="mt20 mb10 score-body">
              <el-col :span="6" v-if="testTask.isShowScore === '是' && shortStatus">
                <span class="ml10">
                  <font class="score-tit" v-text="Number(testTask.examUser.score)"></font>分
                </span>
                <p class="mt15">
                  总分
                  <span v-text="testScores"></span>分
                </p>
              </el-col>
              <el-col :span="12">
                <ul class="score-list">
                  <li>
                    <span>答对</span>
                    <span class="succe-span">{{correctRs}}道</span>
                  </li>
                  <li>
                    <span>答错</span>
                    <span class="dange-span">{{mistakeRs}}道</span>
                  </li>
                  <li>
                    <span>未批阅</span>
                    <span class="audit-span">{{notReadRs}}道</span>
                  </li>
                </ul>
              </el-col>
            </el-row>
            <template v-if="Boolean(testTask.examUser.reviewTime) && Boolean(testTask.examUser.teacherSay)">
              <el-row class="mt20 mb10 remark">
                <span>评语：</span>
                <div v-html="testTask.examUser.teacherSay"></div>
              </el-row>
            </template>
          </div>

          <!--  -->
          <div
            class="panel"
            v-for="(item, index) in testTask.questionList"
            :key="index"
            :id="'anchor_'+ (index+1)"
          >
            <el-row class="topicTit">
              <span class="top-num">{{index+1}}.</span>
              <span class="top-type">【 {{item.question.type}} 】</span>
              <template v-if="testTask.isShowScore === '是'">
                <span class="top-score">{{item.score}} 分</span>
              </template>
            </el-row>
            <el-row>
              <el-row v-html="item.question.stem" class="task-title"></el-row>
            </el-row>

            <ul class="label-con">
              <li class="label-list clearfix" v-for="(label, index) in item.question.itemList" :key="index">
                <span class="fl mr10" v-text="optionLetterName(index) + '.'"></span>
                <div class="fl" v-html="label.content"></div>
                <!-- <span v-html="label.content.replace(/<[/]?p>/g, '')"></span> -->
              </li>
            </ul>
            <template v-if="item.question.type === '单选'">
              <el-row>
                <span v-if="ifCheck">
                  正确答案是：
                  <span v-for="(label, index) in item.question.itemList" :key="index">
                    <template v-if="label.correctAnswer === true">
                      <span v-text="optionLetterName(index)" class="answer-color"></span>
                    </template>
                  </span>
                </span>

                <span class="ml15">
                  你的答案是：
                  <template v-if="Boolean(testTask.questionList[index].answer)">
                    <span v-for="(ele, idx) in item.question.itemList" :key="idx">
                      <template
                        v-if="JSON.parse(testTask.questionList[index].answer)[0] === ele.id"
                      >
                        <span v-text="optionLetterName(idx)"></span>
                      </template>
                    </span>
                  </template>
                </span>
              </el-row>
            </template>

            <template v-if="item.question.type === '多选'">
              <el-row>
                <span v-if="ifCheck">
                正确答案是：
                  <span v-for="(label, index) in item.question.itemList" :key="index">
                    <template v-if="label.correctAnswer === true">
                      <span v-text="optionLetterName(index)" class="answer-color"></span>
                    </template>
                  </span>
                </span>
                <span class="ml15">
                你的答案是：
                  <template v-if="Boolean(testTask.questionList[index].answer)">
                    <span v-for="(ele, idx) in item.question.itemList" :key="idx">
                      <span
                        v-for="(item) in JSON.parse(testTask.questionList[index].answer)"
                        :key="item.id"
                      >
                        <template v-if="ele.id === item">
                          <span v-text="optionLetterName(idx)"></span>
                        </template>
                      </span>
                    </span>
                  </template>
                </span>
              </el-row>
            </template>

            <template v-if="item.question.type === '填空'">
              <el-row>
                <el-tabs type="border-card">
                  <el-tab-pane label="学员答案" key="stuAnswer">
                    <template>
                      <template v-if="Boolean(testTask.questionList[index].answer)">
                        <span>{{testTask.questionList[index].answer}}</span>
                      </template>
                    </template>
                  </el-tab-pane>
                  <el-tab-pane label="正确答案" v-if="ifCheck" key="correctAnswer">
                    <span v-for="(label, index) in JSON.parse(item.question.correctAnswer)" :key="index">
                      {{label}}
                    </span>
                  </el-tab-pane>
                  <el-tab-pane label="得分" key="score">
                    <el-row v-text="item.myScore" style="margin: 10px 0;" class="myScore"></el-row>
                  </el-tab-pane>
                </el-tabs>
              </el-row>
            </template>

            <template v-if="item.question.type === '简答'">
              <template v-if="!item.reviewTime">
                <el-row class="audit">老师正在批阅，请稍后</el-row>
              </template>
              <template v-else>
                <el-tabs type="border-card">
                  <el-tab-pane label="学员答案">
                    <template>
                      <pre v-text="item.answer" class="short-answer"></pre>
                      <!-- <el-row v-text="item.answer" class="short-answer"></el-row> -->
                    </template>
                  </el-tab-pane>
                  <el-tab-pane label="正确答案">
                    <el-row v-html="item.question.correctAnswer" style="margin: 10px 0;"></el-row>
                  </el-tab-pane>
                  <el-tab-pane label="得分">
                    <el-row v-text="item.myScore" style="margin: 10px 0;" class="myScore"></el-row>
                  </el-tab-pane>
                  <el-tab-pane label="评语">
                    <el-row v-html="item.teacherSay" style="margin: 10px 0;"></el-row>
                  </el-tab-pane>
                </el-tabs>

                <el-row class="mt20" v-if="item.reviewTime">
                  <span>题目解析：</span>
                  <el-row class="mt15">
                    <template v-if="!item.question.analysis.replace(/<([a-z]+?)(?:\s+?[^>]*?)?>\s*?<br><\/\1>/ig, '')">
                      <p class="analysis-span">无解析内容</p>
                    </template>
                    <template v-else>
                      <div v-html="item.question.analysis" class="analysis-span"></div>
                    </template>
                  </el-row>
                </el-row>
              </template>
            </template>

            <template v-if="item.question.type !== '简答'">
              <el-row class="mt20">
                <span>题目解析：</span>
                <el-row class="mt15">
                  <template v-if="!item.question.analysis.replace(/<([a-z]+?)(?:\s+?[^>]*?)?>\s*?<br><\/\1>/ig, '')">
                    <p class="analysis-span">无解析内容</p>
                  </template>
                  <template v-else>
                    <div v-html="item.question.analysis" class="analysis-span"></div>
                  </template>
                </el-row>
              </el-row>
            </template>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
          <el-row class="task-fixed">
            <div class="task-dtk">
              <h5 class="card-tit">答题卡</h5>
              <ul :class="[Boolean(isSeen)?'card-list-show':'card-list' + ' clearfix']" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
                <li
                  v-for="(item, index) of answerStatusList"
                  :key="index"
                  @click="skipAnchor(index+1)"
                  :class="switchCard(item)"
                >{{index+1}}</li>
              </ul>
              <!-- 答题卡颜色对应的状态 -->
              <el-row class="card-explain">
                <span>
                  <i class="bg-card-success"></i>正确
                </span>
                <span>
                  <i class="bg-card-error"></i>错误
                </span>
                <span>
                  <i class="bg-card-default"></i>未批阅
                </span>
              </el-row>
              <el-row style="text-align: center;">
                <el-button
                  size="small"
                  round
                  @click="SubPaperAgain"
                  type="primary"
                  style="color: #212121;margin: 10px 0;"
                  :disabled="testTask.isRepeatable === '否'"
                >再次尝试</el-button>
                <el-button
                  v-if="$route.query.type === '刷题'"
                  size="small"
                  round
                  @click="checkAnswer"
                  type="info"
                  :disabled="ifCheck"
                >查看答案</el-button>
              </el-row>
            </div>
            <!-- 上一次 & 下一次 历史提交记录 -->
            <el-row style="margin-top: 15px;">
              <el-button type="primary" size="mini" @click="prevSub" plain v-if="currentSub !== 1">上一次提交</el-button>
              <el-button type="primary" size="mini" @click="newxtSub" plain v-if="currentSub !== submitList.length">下一次提交</el-button>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row class="prewPaper">
        <img src="../../../../assets/images/read.png" style="width: 200px;">
        <span>老师正在阅卷,请稍后查看</span>
      </el-row>
    </template>
  </div>
</template>

<script>
import getOptionName from "@/mixins/getOptionName";
import Prism from "prismjs"; //引入插件
import $axios from '@/utils/request'

export default {
  mixins: [getOptionName],
  props: {
    taskID: {
      default: '',
      type: String
    },
    isReview: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      screenHeight: "", // 高度
      answerList: [], // 答题卡-（数据）
      multipleOption: [], // 答题卡多选数据
      testTask: {
        examUser: {} // 学员考试结果信息
      },
      hr: 0,
      min: 0,
      sec: 0,
      testScores: 0, // 试卷总分
      correctRs: 0, // 正确结果个数
      mistakeRs: 0, // 错误结果个数
      notReadRs: 0, // 未批阅（客观题）个数
      TotalNum: 0, // 试卷总题目 数量
      // StuNum: 0, // 学生提交带 答案的题目数量
      answerStatusList: [], // 答题卡-状态
      shortStatus: true,
      submitList: [], // 答题记录列表
      currentSub: 0, // 默认展示最后一次提交记录
      questionArr: [], // 简答题数组
      isSeen: false,
      ifCheck: false, // 是否显示答案
    };
  },
  mounted() {
    if (this.isReview) {
      this.getSubmitList()
      // this.getExamResult();
    }
  },
  methods: {
    // 题目锚点跳转
    skipAnchor(index) {
      let domAnchor = `#anchor_${index}`;
      document.querySelector(domAnchor).scrollIntoView(true);
    },

    // 答题卡状态-渲染
    switchCard(state) {
      switch (state.answer) {
        case "未批阅":
          return "bg-card-default";
          break;
        case "回答正确":
          return "bg-card-success";
          break;
        case "回答错误":
          return "bg-card-error";
          break;
        default:
          return "bg-card-default";
          break;
      }
    },

    optionChange() {
      this.$forceUpdate();
    },

    // 提交试卷
    SubPaperAgain() {
      this.$emit("SubPaperAgain");
    },

    // 上一次提交
    prevSub () {
      this.answerStatusList = [] // 答题卡置为空
      this.currentSub --
      if (this.currentSub < 1) {
        window.$msg('已是最早一次提交', 2)
        this.currentSub = 1
        return false
      } else {
        this.getExamResult(this.submitList[this.currentSub - 1])
      }
    },

    // 下一次提交
    newxtSub () {
      this.answerStatusList = [] // 答题卡置为空
      this.currentSub ++
      if (this.currentSub > this.submitList.length) {
        window.$msg('已是最后一次提交', 2)
        this.currentSub = this.submitList.length
        return false
      } else {
        this.getExamResult(this.submitList[this.currentSub - 1])
      }
    },

    // 获取作答记录ID列表
    getSubmitList () {
      $axios.fPost("/meredu/exam/getSubmitList", {
        taskId: this.taskID
      }).then(res => {
        // console.log('res', res)
        this.submitList = res.body
        this.currentSub = this.submitList.length
        this.getExamResult(this.submitList[this.submitList.length - 1])
      })
    },

    // 获取试卷详细信息
    getExamResult(euId) {
      this.answerStatusList = []
      this.testScores = 0 // 试卷总分
      this.correctRs = 0 // 正确结果个数
      this.mistakeRs = 0 // 错误结果个数
      this.notReadRs = 0 // 未批阅（客观题）个数
      $axios.fGet("/meredu/exam/result", {
        euId
      })
        .then(res => {
          if (res.state === "success") {
            this.$nextTick(() => {
              this.testTask = Object.assign({}, res.body);
              this.TotalNum = this.testTask.questionList.length; // 试卷总题目数量
              // this.StuNum = this.testTask.examUser.questionUserList.length; // 学生作答题目数量
              this.testTask.questionList.forEach((item, index) => {
                // if (item.question.type === "单选") {
                //   item.answer = "";
                // } else if (item.question.type === "多选") {
                //   item.answer = [];
                // } else {
                //   item.answer = "";
                // }
                this.testScores += Number(item.score);
                this.testTask.examUser.questionUserList.forEach( (label, idx) => {
                  if (item.questionId === label.questionId && Boolean(label.answer)) {
                    // console.log('111', item)
                    // console.log('222', label)
                    item.answer = label.answer
                    item.reviewTime = label.reviewTime
                    item.teacherSay = label.teacherSay
                    item.myScore = label.score
                    let answerArr = []
                    if (item.question.type === "填空") {
                      // console.log('填空', item)
                      // console.log('填空', label)
                      if (Number(item.score) === Number(label.score)) {
                        this.correctRs++;
                        this.answerStatusList.push({
                          id: item.questionId,
                          answer: "回答正确"
                        });
                      } else {
                        this.mistakeRs++;
                        this.answerStatusList.push({
                          id: item.questionId,
                          answer: "回答错误"
                        });
                      }
                    } else if (item.question.type !== "简答" && item.question.type !== '填空') {
                      item.question.itemList.findIndex(list => {
                        // 从question.itemList中获取题目的正确选项
                        if (list.correctAnswer === true) {
                          answerArr.push(list.id);
                        }
                      })
                      if (JSON.stringify(answerArr).replace(/ /g, '') === label.answer) {
                        this.correctRs++;
                        this.answerStatusList.push({
                          id: item.questionId,
                          answer: "回答正确"
                        });
                      } else {
                        this.mistakeRs++;
                        this.answerStatusList.push({
                          id: item.questionId,
                          answer: "回答错误"
                        });
                      }
                    } else if (item.question.type === "简答") {
                      this.questionArr.push(label)
                      this.questionArr.findIndex(list => {
                        // 获取简答题批阅状态
                        if (!list.reviewTime) {
                          this.shortStatus = false
                        } else {
                          this.shortStatus = true
                        }
                      });
                      if(!label.reviewTime) {
                        this.notReadRs++;
                          this.answerStatusList.push({
                          id: item.questionId,
                          answer: "未批阅"
                        });
                      } else {
                        if (Number(item.myScore) === Number(item.score)) {
                          this.correctRs++;
                          this.answerStatusList.push({
                            id: item.questionId,
                            answer: "回答正确"
                          });
                        } else {
                          this.mistakeRs++;
                          this.answerStatusList.push({
                            id: item.questionId,
                            answer: "回答错误"
                          });
                        }
                      }
                    }
                  } else if (item.questionId === label.questionId && Boolean(!label.answer)) {
                    item.answer = label.answer
                    item.reviewTime = label.reviewTime
                    item.teacherSay = label.teacherSay
                    item.myScore = label.score
                    if (item.question.type !== "简答") {
                      this.answerStatusList.push({
                        id: item.questionId,
                        answer: "回答错误"
                      });
                      this.mistakeRs++
                    } else {
                      this.questionArr.push(label)
                      this.questionArr.findIndex(list => {
                        // 获取简答题批阅状态
                        if (!list.reviewTime) {
                          this.shortStatus = false
                        } else {
                          this.shortStatus = true
                        }
                      });
                      if(!label.reviewTime) {
                        this.notReadRs++;
                          this.answerStatusList.push({
                          id: item.questionId,
                          answer: "未批阅"
                        });
                      } else {
                        // this.shortStatus = true
                        if (Number(item.myScore) === Number(item.score)) {
                          this.correctRs++;
                          this.answerStatusList.push({
                            id: item.questionId,
                            answer: "回答正确"
                          });
                        } else {
                          this.mistakeRs++;
                          this.answerStatusList.push({
                            id: item.questionId,
                            answer: "回答错误"
                          });
                        }
                      }
                    }
                  }
                })
              })
            })
            this.$nextTick(() => {
              setTimeout(()=> {
                Prism.highlightAll()
              }, 0)
            })
          } else {
            $msg(res.errMsg, 2);
          }
        })
        .catch(err => {
          $msg("接口请求失败", 1);
          console.log("err", err);
        });
    },

    // 计算答题时间
    changeMinutes(createTime, finishTime) {
      const now = Date.parse(new Date(createTime));
      const end = Date.parse(new Date(finishTime));
      const msec = end - now;
      let hr =
        parseInt((msec / 1000 / 60 / 60) % 24) > 9
          ? parseInt((msec / 1000 / 60 / 60) % 24)
          : "0" + parseInt((msec / 1000 / 60 / 60) % 24);
      let min =
        parseInt((msec / 1000 / 60) % 60) > 9
          ? parseInt((msec / 1000 / 60) % 60)
          : "0" + parseInt((msec / 1000 / 60) % 60);
      let sec =
        parseInt((msec / 1000) % 60) > 9
          ? parseInt((msec / 1000) % 60)
          : "0" + parseInt((msec / 1000) % 60);
      let elapsedTime = hr + ":" + min + ":" + sec;
      return elapsedTime;
    },

    onMouseOver () {
      this.isSeen = true
    },

    onMouseOut () {
      this.isSeen = false
    },

    // 刷题活动主动查看答案
    checkAnswer () {
      this.$confirm('查看答案后答题记录不再计入统计，是否确定查看', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.ifCheck = true
          $axios.fGet("/meredu/exam/viewAnswer", {
            taskId: this.$route.query.id
          }).then(res => {
            if (res.state === "success") {
              console.log('res', res)
            } else {
              $msg(res.errMsg, 2);
            }
          })
          .catch(err => {
            $msg("请求失败", 1);
          });
        })
        .catch(() => {})
    },
  }
};
</script>

<style lang="scss" scoped>
.short-answer {
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin: 10px 0;
}
.answer-color {
  color: #f5c319;
}
.task-tit {
  font-size: 24px;
  font-weight: 700;
  color: #444;
}
.task-left {
  // width: 75%;
  width: 938px;
  height: 100%;
  overflow: auto;
  // margin-right: 2%;
  margin-right: 24px;
}
.task-right {
  position: relative;
}

.task-fixed {
  z-index:999;
  width: 100%;
  // min-height: 500px;
  // height: auto;
  margin-bottom: 35px;
  overflow: auto;
  .task-time {
    padding: 0 5px 10px 5px;
  }
  .task-dtk {
    background: #fff;
    box-sizing: border-box;
    padding: 15px 15px 20px 21px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
}

.topicTit {
  font-size: 20px;
  color: #212121;
  margin-bottom: 10px;
  span {
    display: inline-block;
  }
  .top-num {
    margin-right: 10px;
  }
  .top-type {
    margin-right: 10px;
  }
}

.label-list {
  line-height: 30px;
  margin-bottom: 10px;
  width: 70%;
  box-sizing: border-box;
  padding-left: 20px;
}

.task-title {
  display: inline-block;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 15px;
}

.myScore {
  color: #CE433F;
  font-weight: 700;
}

.card-tit {
  font-size: 18px;
  color: #212121;
}

.card-list {
  position: relative;
  max-height: 200px;
  overflow: hidden;
  margin-top: 10px;
  li {
    float: left;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.card-list-show {
  position: relative;
  max-height: 200px;
  overflow: auto;
  margin-top: 10px;
  li {
    float: left;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.bg-card-default {
  border: 1px solid #909399 !important;
  background-color: #909399 !important;
  color: #fff !important;
}
.bg-card-success {
  border: 1px solid #4da54d !important;
  background-color: #4da54d !important;
  color: #fff !important;
}
.bg-card-error {
  border: 1px solid #ce433f !important;
  background-color: #ce433f !important;
  color: #fff !important;
}
.bg-card {
  border: 1px solid #f5c319 !important;
  background-color: #f5c319 !important;
  color: #fff !important;
}

.card-explain {
  padding-left: 5px;
  margin: 10px 0;
  span {
    display: inline-block;
    color: 14px;
    margin-right: 10px;
    i {
      display: inline-block;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 4px;
    }
  }
}

.analysis-span {
  box-sizing: border-box;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  padding: 15px 0;
  padding-left: 20px;
}

.panel {
  padding: 15px;
  margin-bottom: 24px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.score-body {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-tit {
  font-size: 40px;
  color: #f60;
}

.score-list {
  li {
    &:nth-child(n + 1) {
      span {
        border-bottom: 0;
      }
    }
    &:nth-child(3) {
      span {
        border-bottom: 1px solid #ddd;
      }
    }
    span {
      box-sizing: border-box;
      border: 1px solid #dddddd;
      display: inline-block;
      line-height: 40px;
      text-align: center;
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
        border-left: 0;
      }
    }
  }
}

.remark {
  width: 100%;
  padding: 10px;
  border: 1px solid #CDDFB4;
  background: #F2FAE3;
  font-size: 16px;
  line-height: 30px;
}

.audit {
  width: 100%;
  padding: 10px;
  border: 1px solid #FFD3BC;
  background: #FFF9CD;
  font-size: 16px;
  line-height: 30px;
  color: #FF9360;
}

.prewPaper {
  width: 905px;
  height: 180px;
  background: #fff;
  border: 1px solid rgba(121, 121, 121, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  span {
    display: inline-block;
    margin-left: 35px;
    font-size: 26px;
    color: #333;
    font-weight: 700;
  }
}

.succe-span {
  color: #43bc60;
}
.dange-span {
  color: #ed3e3e;
}
.audit-span {
  color: #f60;
}

/deep/ .el-radio-group {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 15px;
  margin-bottom: 13px;
  &:hover {
    background: #eee;
  }
}

/deep/ .el-checkbox-group {
  padding-left: 20px;
  display: inline-block;
  margin-bottom: 13px;
}

@media (min-width: 768px){ //>=768的设备
  .task-con {
    width: 600px;
    margin: 0 auto 0;
    padding-top: 24px;
    overflow: hidden;
  }
  .task-fixed {
    width: 100%;
    position: static;
    top: 0;
  }
}

@media (min-width: 992px){ //>=992的设备
  .task-con {
    width: 740px;
    margin: 0 auto 0;
    padding-top: 24px;
    overflow: hidden;
  }
  .task-fixed {
    width: 100%;
    position: static;
    top: 0;
  }
}

@media (min-width: 1200px){ //>=1200的设备
  .task-con {
    width: 1160px;
    margin: 0 auto 0;
    padding-top: 24px;
    overflow: hidden;
  }
  .task-fixed {
    position:fixed;
    z-index:999;
    top: 83px;
    width: 270px;
    // height: auto;
    // min-height: 500px;
    overflow: auto;
  }
}
</style>
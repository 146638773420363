var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticStyle: { height: "100%", width: "100%" },
    attrs: { id: "container" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _vm.taskInfo.type !== "编程"
        ? _c(
            "el-header",
            {
              staticClass: "header",
              staticStyle: { display: "block", "line-height": "60px" },
            },
            [
              _c("span", { staticClass: "fl" }, [
                _vm._v(_vm._s(_vm.taskInfo.title)),
              ]),
              _vm.taskID
                ? _c(
                    "el-row",
                    { staticClass: "fr" },
                    [
                      !_vm.taskInfo.finishTime
                        ? [
                            _c(
                              "el-popover",
                              {
                                staticClass: "footer-condition",
                                attrs: {
                                  placement: "top-end",
                                  trigger: "click",
                                  width: "150",
                                },
                              },
                              [
                                _c("el-row", { staticClass: "condition-con" }, [
                                  _c("p", [_vm._v("满足以下条件：")]),
                                  _vm.taskInfo.type === "图文" ||
                                  _vm.taskInfo.type === "外链" ||
                                  _vm.taskInfo.type === "python" ||
                                  _vm.taskInfo.type === "pdf" ||
                                  _vm.taskInfo.type === "视频" ||
                                  _vm.taskInfo.type === "ppt"
                                    ? _c("p", [
                                        _vm._v(
                                          " 至少观看" +
                                            _vm._s(_vm.taskPremise) +
                                            "分钟 "
                                        ),
                                      ])
                                    : _vm.taskInfo.type === "编程"
                                    ? _c("p", [_vm._v("完成所有已开放的题目")])
                                    : _vm.taskInfo.type === "测试"
                                    ? _c("p", [_vm._v("提交试卷")])
                                    : _vm.taskInfo.type === "上传"
                                    ? _c("p", [_vm._v("完成上传作品")])
                                    : _vm.taskInfo.type === "goc"
                                    ? _c("p", [
                                        _vm.gocSourceContent.type === "代码"
                                          ? _c("span", [_vm._v("提交代码")])
                                          : _c("span", [
                                              _vm._v(
                                                "至少观看" +
                                                  _vm._s(_vm.taskPremise) +
                                                  "分钟"
                                              ),
                                            ]),
                                      ])
                                    : _vm.taskInfo.type === "scratch"
                                    ? _c("p", [_vm._v("保存作品")])
                                    : _vm.taskInfo.type === "Pygame"
                                    ? _c("p", [_vm._v("保存作品")])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-question",
                                    }),
                                    _vm._v("任务完成条件 "),
                                  ]
                                ),
                              ],
                              1
                            ),
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-btn",
                                  attrs: {
                                    disabled: _vm.isEnd,
                                    round: "",
                                    size: "small",
                                    type: "primary",
                                  },
                                  on: { click: _vm.taskFinish },
                                },
                                [_vm._v("完成任务 ")]
                              ),
                            ],
                          ]
                        : [
                            _c(
                              "el-button",
                              {
                                staticClass: "footer-btn",
                                attrs: {
                                  round: "",
                                  size: "small",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.nextTask()
                                  },
                                },
                              },
                              [_vm._v("已完成、进入下个任务 ")]
                            ),
                          ],
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "footer-btn",
                            attrs: {
                              round: "",
                              size: "small",
                              type: "primary",
                            },
                            on: { click: _vm.taskListHandler },
                          },
                          [_vm._v("任务列表 ")]
                        ),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.taskInfo.type === "scratch"
                ? _c(
                    "el-row",
                    { staticClass: "fr" },
                    [
                      _c("el-row", { staticClass: "fr" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "line-height": "60px",
                              height: "60px",
                            },
                          },
                          [
                            _c(
                              "el-carousel",
                              {
                                ref: "scratchCarousel",
                                staticStyle: {
                                  width: "300px",
                                  "margin-right": "20px",
                                  "border-radius": "50px",
                                },
                                attrs: {
                                  height: "45px",
                                  autoplay: _vm.autoplay,
                                  loop: _vm.autoplay,
                                  arrow: "always",
                                },
                                on: { change: _vm.scratchQuestionChange },
                              },
                              _vm._l(
                                _vm.scratchQuestionForTaskList,
                                function (item, index) {
                                  return _c(
                                    "el-carousel-item",
                                    { key: index },
                                    [
                                      _c("span", { staticClass: "small" }, [
                                        _vm._v(
                                          "第" + _vm._s(index + 1) + "道题"
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                            _vm.isChooseScratch
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "15px" } },
                                    [
                                      _vm._v(
                                        " 作品名称：" +
                                          _vm._s(_vm.scratchChoose) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "15px" },
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        round: "",
                                        loading: _vm.isGoShow,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveScratch("1")
                                        },
                                      },
                                    },
                                    [_vm._v("保 存 ")]
                                  ),
                                  _c(
                                    "el-popover",
                                    {
                                      ref: "taskPover",
                                      staticClass: "mr-10",
                                      attrs: {
                                        placement: "top",
                                        width: "350",
                                        trigger: "click",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "taskPoverForm",
                                          attrs: {
                                            model: _vm.formData,
                                            rules: _vm.rules,
                                            "label-width": "100px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "作品名称：",
                                                prop: "titleBesides",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入作品名称",
                                                },
                                                on: { input: _vm.forceUpdate },
                                                model: {
                                                  value:
                                                    _vm.formData.titleBesides,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "titleBesides",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.titleBesides",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            margin: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveScratch("2")
                                                },
                                              },
                                            },
                                            [_vm._v("确 定 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                              },
                                              on: { click: _vm.taskClose },
                                            },
                                            [_vm._v("取 消 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            size: "small",
                                            type: "primary",
                                            round: "",
                                            loading: _vm.isGoShow,
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v("另存为 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-popover",
                                    {
                                      ref: "taskPover",
                                      staticClass: "mr-10",
                                      attrs: {
                                        placement: "top",
                                        width: "350",
                                        trigger: "click",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "taskPoverForm",
                                          attrs: {
                                            model: _vm.formData,
                                            rules: _vm.rules,
                                            "label-width": "100px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "作品名称：",
                                                prop: "title",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入作品名称",
                                                },
                                                on: { input: _vm.forceUpdate },
                                                model: {
                                                  value: _vm.formData.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "title",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "formData.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            margin: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveScratch("1")
                                                },
                                              },
                                            },
                                            [_vm._v("确 定 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                              },
                                              on: { click: _vm.taskClose },
                                            },
                                            [_vm._v("取 消 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            size: "small",
                                            type: "primary",
                                            round: "",
                                            loading: _vm.isGoShow,
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v("保 存 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "15px" },
                                attrs: {
                                  size: "small",
                                  type: "info",
                                  round: "",
                                  plain: "",
                                },
                                on: { click: _vm.specification },
                              },
                              [_vm._v("任务说明 ")]
                            ),
                            _c(
                              "el-popover",
                              {
                                ref: "taskPoverScratch",
                                attrs: {
                                  placement: "top-end",
                                  width: "230",
                                  trigger: "click",
                                },
                                on: { show: _vm.submitRecord },
                              },
                              [
                                _vm.scratchList.length === 0
                                  ? [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_c("span", [_vm._v("暂无提交记录")])]
                                      ),
                                    ]
                                  : _c(
                                      "ul",
                                      {
                                        staticClass: "python-list record-list",
                                      },
                                      _vm._l(_vm.scratchList, function (item) {
                                        return _c("li", { key: item.id }, [
                                          _c(
                                            "p",
                                            { staticClass: "clearfix" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "fl",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      item.createTime.substring(
                                                        0,
                                                        19
                                                      ),
                                                    placement: "top-start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "title-link pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.previewScratch(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "info",
                                      round: "",
                                      plain: "",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("提交记录 ")]
                                ),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.taskInfo.type === "Pygame"
                ? _c(
                    "el-row",
                    { staticClass: "fr" },
                    [
                      _vm.isChoosePygame
                        ? [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "15px" } },
                              [
                                _vm._v(
                                  " 作品名称：" + _vm._s(_vm.pygameChoose) + " "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "15px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  round: "",
                                  loading: _vm.isGoShow,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.savePygame("1")
                                  },
                                },
                              },
                              [_vm._v("保 存")]
                            ),
                            _c(
                              "el-popover",
                              {
                                ref: "taskPover",
                                staticClass: "mr-10",
                                attrs: {
                                  placement: "top",
                                  width: "350",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "taskPoverForm",
                                    attrs: {
                                      model: _vm.formData,
                                      rules: _vm.rules,
                                      "label-width": "100px",
                                    },
                                    nativeOn: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作品名称：",
                                          prop: "titleBesides",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入作品名称",
                                          },
                                          on: { input: _vm.forceUpdate },
                                          model: {
                                            value: _vm.formData.titleBesides,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "titleBesides",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "formData.titleBesides",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.savePygame("2")
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "info", size: "mini" },
                                        on: { click: _vm.taskClose },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "primary",
                                      round: "",
                                      loading: _vm.isGoShow,
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("另存为")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "el-popover",
                              {
                                ref: "taskPover",
                                staticClass: "mr-10",
                                attrs: {
                                  placement: "top",
                                  width: "350",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "taskPoverForm",
                                    attrs: {
                                      model: _vm.formData,
                                      rules: _vm.rules,
                                      "label-width": "100px",
                                    },
                                    nativeOn: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作品名称：",
                                          prop: "title",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入作品名称",
                                          },
                                          model: {
                                            value: _vm.formData.title,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "title",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "formData.title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.savePygame("1")
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "info", size: "mini" },
                                        on: { click: _vm.taskClose },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "primary",
                                      round: "",
                                      loading: _vm.isGoShow,
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("保 存")]
                                ),
                              ],
                              1
                            ),
                          ],
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "15px" },
                          attrs: {
                            size: "small",
                            type: "info",
                            round: "",
                            plain: "",
                          },
                          on: { click: _vm.specification },
                        },
                        [_vm._v("任务说明")]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-end",
                            width: "230",
                            trigger: "hover",
                          },
                          on: { show: _vm.submitPygameRecord },
                        },
                        [
                          _vm.pygameList.length === 0
                            ? [
                                _c(
                                  "el-row",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("暂无提交记录")])]
                                ),
                              ]
                            : _c(
                                "ul",
                                { staticClass: "python-list record-list" },
                                _vm._l(_vm.pygameList, function (item) {
                                  return _c("li", { key: item.id }, [
                                    _c(
                                      "p",
                                      { staticClass: "clearfix" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "fl",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                item.createTime.substring(
                                                  0,
                                                  19
                                                ),
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title-link pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.previewPygame(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                slot: "reference",
                                type: "info",
                                round: "",
                                plain: "",
                                size: "small",
                              },
                              slot: "reference",
                            },
                            [_vm._v("提交记录")]
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.taskInfo.type === "python"
                ? _c("el-row", { staticClass: "fr" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "line-height": "60px",
                          height: "60px",
                        },
                      },
                      [
                        _c(
                          "el-carousel",
                          {
                            ref: "pythonCarousel",
                            staticStyle: {
                              width: "300px",
                              "border-radius": "50px",
                              "margin-right": "30px",
                            },
                            attrs: {
                              height: "45px",
                              autoplay: _vm.autoplay,
                              loop: _vm.autoplay,
                              arrow: "always",
                            },
                            on: { change: _vm.pythonQuestionChange },
                          },
                          _vm._l(
                            _vm.pythonQuestionForTaskList,
                            function (item, index) {
                              return _c("el-carousel-item", { key: index }, [
                                _c("span", { staticClass: "small" }, [
                                  _vm._v("第" + _vm._s(index + 1) + "道题"),
                                ]),
                              ])
                            }
                          ),
                          1
                        ),
                        _vm.editPython !== null
                          ? [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "15px" } },
                                [
                                  _vm._v(
                                    " 作品名称：" +
                                      _vm._s(_vm.editPython.title) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    round: "",
                                    loading: _vm.isGoShow,
                                  },
                                  on: { click: _vm.savePython },
                                },
                                [_vm._v("保 存 ")]
                              ),
                              _c(
                                "el-popover",
                                {
                                  ref: "PythontaskPover",
                                  staticClass: "mr-10",
                                  attrs: {
                                    placement: "top",
                                    width: "350",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "taskPoverForm",
                                      attrs: {
                                        model: _vm.formData,
                                        rules: _vm.rules,
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "作品名称：",
                                            prop: "titleBesides",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入作品名称",
                                            },
                                            on: { input: _vm.forceUpdate },
                                            model: {
                                              value: _vm.formData.titleBesides,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "titleBesides",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "formData.titleBesides",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        margin: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.taskConfirm(
                                                "python",
                                                "again"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("确 定 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "info", size: "mini" },
                                          on: { click: _vm.pythonTaskClose },
                                        },
                                        [_vm._v("取 消 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "15px" },
                                      attrs: {
                                        slot: "reference",
                                        size: "small",
                                        type: "primary",
                                        round: "",
                                        loading: _vm.isGoShow,
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("另存为 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "el-popover",
                                {
                                  ref: "PythontaskPover",
                                  staticClass: "mr-10",
                                  attrs: {
                                    placement: "top",
                                    width: "350",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "taskPoverForm",
                                      attrs: {
                                        model: _vm.formData,
                                        rules: _vm.rules,
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "作品名称：",
                                            prop: "title",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入作品名称",
                                            },
                                            on: { input: _vm.forceUpdate },
                                            model: {
                                              value: _vm.formData.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "title",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "formData.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        margin: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.taskConfirm("python")
                                            },
                                          },
                                        },
                                        [_vm._v("确 定 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "info", size: "mini" },
                                          on: { click: _vm.pythonTaskClose },
                                        },
                                        [_vm._v("取 消 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "15px" },
                                      attrs: {
                                        slot: "reference",
                                        size: "small",
                                        type: "primary",
                                        round: "",
                                        loading: _vm.isGoShow,
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("保 存 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                        _c(
                          "el-popover",
                          {
                            ref: "popoverPython",
                            staticStyle: { "margin-right": "25px" },
                            attrs: {
                              placement: "top-end",
                              width: "230",
                              trigger: "click",
                            },
                            on: { show: _vm.getListPythonTaskCode },
                          },
                          [
                            _vm.pythonList.length === 0
                              ? [
                                  _c(
                                    "el-row",
                                    { staticStyle: { "text-align": "center" } },
                                    [_c("span", [_vm._v("暂无提交记录")])]
                                  ),
                                ]
                              : _c(
                                  "ul",
                                  { staticClass: "python-list record-list" },
                                  _vm._l(_vm.pythonList, function (item) {
                                    return _c("li", { key: item.id }, [
                                      _c(
                                        "p",
                                        { staticClass: "clearfix" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "fl",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  item.createTime.substring(
                                                    0,
                                                    19
                                                  ),
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "title-link pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewPython(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                          _c("span", {
                                            staticClass: "fr",
                                            style:
                                              "color" +
                                              ":" +
                                              _vm.pythonColor(item.isCorrect),
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.pythonText(item.isCorrect)
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  round: "",
                                  size: "small",
                                  type: "info",
                                },
                                slot: "reference",
                              },
                              [_vm._v(" 提交记录 ")]
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.taskInfo.type === "goc"
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-row",
                        { staticClass: "fr" },
                        [
                          _vm.isShowGoc
                            ? [
                                _vm.editGoC !== null
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " 作品名称：" +
                                              _vm._s(_vm.editGoC.title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            round: "",
                                            loading: _vm.isGoShow,
                                          },
                                          on: { click: _vm.submitGoc },
                                        },
                                        [_vm._v("保 存 ")]
                                      ),
                                      _c(
                                        "el-popover",
                                        {
                                          ref: "taskPover",
                                          staticClass: "mr-10",
                                          attrs: {
                                            placement: "top",
                                            width: "350",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              ref: "taskPoverForm",
                                              attrs: {
                                                model: _vm.formData,
                                                rules: _vm.rules,
                                                "label-width": "100px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "作品名称：",
                                                    prop: "titleBesides",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入作品名称",
                                                    },
                                                    on: {
                                                      input: _vm.forceUpdate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .titleBesides,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "titleBesides",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.titleBesides",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                margin: "0",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.taskConfirm(
                                                        "goc",
                                                        "again"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("确 定 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "info",
                                                    size: "mini",
                                                  },
                                                  on: { click: _vm.taskClose },
                                                },
                                                [_vm._v("取 消 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                size: "small",
                                                type: "primary",
                                                round: "",
                                                loading: _vm.isGoShow,
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("另存为 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "el-popover",
                                        {
                                          ref: "taskPover",
                                          staticClass: "mr-10",
                                          attrs: {
                                            placement: "top",
                                            width: "350",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              ref: "taskPoverForm",
                                              attrs: {
                                                model: _vm.formData,
                                                rules: _vm.rules,
                                                "label-width": "100px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "作品名称：",
                                                    prop: "title",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入作品名称",
                                                    },
                                                    on: {
                                                      input: _vm.forceUpdate,
                                                    },
                                                    model: {
                                                      value: _vm.formData.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "title",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                margin: "0",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.taskConfirm(
                                                        "goc"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("确 定 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "info",
                                                    size: "mini",
                                                  },
                                                  on: { click: _vm.taskClose },
                                                },
                                                [_vm._v("取 消 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                size: "small",
                                                type: "primary",
                                                round: "",
                                                loading: _vm.isGoShow,
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("保 存 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "25px" },
                                    attrs: {
                                      size: "small",
                                      type: "info",
                                      round: "",
                                      plain: "",
                                    },
                                    on: { click: _vm.specification },
                                  },
                                  [_vm._v(" 任务说明 ")]
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    staticStyle: { "margin-right": "25px" },
                                    attrs: {
                                      placement: "top-end",
                                      width: "230",
                                      trigger: "click",
                                    },
                                    on: { show: _vm.getListGoCTaskCode },
                                  },
                                  [
                                    _vm.gocList.length === 0
                                      ? [
                                          _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("暂无提交记录"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      : _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "python-list record-list",
                                          },
                                          _vm._l(_vm.gocList, function (item) {
                                            return _c("li", { key: item.id }, [
                                              _c(
                                                "p",
                                                { staticClass: "clearfix" },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "fl",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          item.createTime.substring(
                                                            0,
                                                            19
                                                          ),
                                                        placement: "top-start",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title-link pointer",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.previewGoC(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          }),
                                          0
                                        ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("提交记录 ")]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-main",
        { class: _vm.getStateTaskInfo(_vm.taskInfo.type) },
        [
          _vm.taskInfo.type === "图文"
            ? [
                _c(
                  "el-row",
                  { staticClass: "content" },
                  [
                    _c("el-col", { attrs: { span: 20 } }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm.taskInfo.type === "上传"
            ? [
                _c("upload-file", {
                  attrs: { taskInfo: _vm.taskInfo },
                  on: { setFinishTime: _vm.setFinishTime },
                }),
              ]
            : _vm.taskInfo.type === "外链" ||
              _vm.taskInfo.type === "补课视频" ||
              _vm.taskInfo.type === "ppt"
            ? [_c("iframe-box", { attrs: { taskInfo: _vm.taskInfo } })]
            : _vm.taskInfo.type === "视频"
            ? [
                _c("videoBox", {
                  attrs: {
                    sourceVideoIds: _vm.sourceVideoId,
                    roles: "management",
                    heights: 60,
                  },
                }),
              ]
            : _vm.taskInfo.type === "python"
            ? [
                _c("iframe", {
                  ref: "leftFrame",
                  attrs: {
                    src: _vm.taskInfo.sourceContent,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    id: "leftFrame",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm.taskInfo.type === "pdf"
            ? [_c("student-pdf", { attrs: { taskID: _vm.taskID } })]
            : _vm.taskInfo.type === "编程"
            ? [
                _c("student-split", {
                  ref: "stuQuestion",
                  attrs: {
                    contest_id: _vm.contest_id,
                    taskID: _vm.taskID,
                    taskInfo: _vm.taskInfo,
                  },
                  on: {
                    getTaskFinish: _vm.getTaskFinish,
                    getSplitpaneCode: _vm.getSplitpaneCode,
                    nextTask: _vm.nextTask,
                    updateView: _vm.taskGetInfo,
                  },
                }),
              ]
            : _vm.taskInfo.type === "测试"
            ? [
                _vm.examStatus === "未考试" ||
                _vm.examStatus === "未交卷" ||
                _vm.examStatus === "再次尝试"
                  ? [
                      _c("exam-begin", {
                        attrs: {
                          sourceId: _vm.taskInfo.sourceId,
                          examBeginTime: _vm.examBeginTime,
                          taskID: _vm.taskID,
                        },
                        on: { checkResult: _vm.checkResult },
                      }),
                    ]
                  : _vm.examStatus === "未公布"
                  ? [
                      _c("exam-result", {
                        attrs: { isReview: false, taskID: _vm.taskID },
                      }),
                    ]
                  : _vm.examStatus === "已公布"
                  ? [
                      _c("exam-result", {
                        attrs: { isReview: true, taskID: _vm.taskID },
                        on: { SubPaperAgain: _vm.SubPaperAgain },
                      }),
                    ]
                  : _vm._e(),
              ]
            : _vm.taskInfo.type === "goc"
            ? [
                _c("iframe", {
                  ref: "gocFrame",
                  attrs: {
                    src: _vm.goSrc,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    id: "gocFrame",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm.taskInfo.type === "scratch"
            ? [
                _c("iframe", {
                  ref: "scratchFrame",
                  attrs: {
                    src: _vm.scratchIde,
                    frameborder: "1",
                    allowfullscreen: "true",
                    width: "100%",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm.taskInfo.type === "Pygame"
            ? [
                _c("PygamePreLook", {
                  ref: "pygameFrame",
                  attrs: { taskInfo: _vm.taskInfo },
                  on: {
                    previewPygame: _vm.previewPygame,
                    taskFinish: _vm.taskFinish,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("task-specification", {
        attrs: { show: _vm.specificationDialog, taskInfo: _vm.taskInfo },
        on: {
          close: function ($event) {
            _vm.specificationDialog = false
          },
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "任务3目录",
            visible: _vm.drawerDrawer,
            direction: "rtl",
            size: "30%",
            "before-close": () => (_vm.drawerDrawer = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "catogory" },
            [
              _c(
                "el-collapse",
                {
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.courseDetails, function (item, index) {
                  return _c(
                    "el-collapse-item",
                    { key: index, attrs: { name: index + 1 } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v(" 第" + _vm._s(index + 1) + "课 "),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                        ]),
                      ]),
                      _vm._l(item.taskList, function (activity, indexs) {
                        return _c(
                          "ul",
                          { key: indexs, staticClass: "task-list" },
                          [
                            activity.status
                              ? [
                                  _c("li", { staticClass: "task-item" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "task-tit",
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewTask(activity)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "task-dy" }, [
                                          _vm._v("任务" + _vm._s(indexs + 1)),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(activity.title) + " "
                                        ),
                                      ]
                                    ),
                                    !activity.startTime
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "task-right",
                                            staticStyle: { color: "#c2c2c2" },
                                          },
                                          [_vm._v("未学习")]
                                        )
                                      : activity.startTime &&
                                        !activity.finishTime
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "task-right",
                                            staticStyle: { color: "#f5c319" },
                                          },
                                          [_vm._v("已学习")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "task-right",
                                            staticStyle: { color: "#90dc61" },
                                          },
                                          [_vm._v("已完成")]
                                        ),
                                  ]),
                                ]
                              : [
                                  _c("li", { staticClass: "task-item" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "task-tit",
                                        staticStyle: { cursor: "default" },
                                      },
                                      [
                                        _c("span", { staticClass: "task-dy" }, [
                                          _vm._v("任务" + _vm._s(indexs + 1)),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(activity.title) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "task-right",
                                        staticStyle: { color: "#c2c2c2" },
                                      },
                                      [_vm._v("敬请期待")]
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="container" style="height: 100%;width: 100%">

  </div>
</template>
<script>
export default {
  name: 'PdfPreview',
  props: {
    taskID: {
      default: '',
      type: String
    }
  },
  watch: {
    taskID() {
      this.getTaskInfo(this.taskID)
    }
  },
  data(){
    return {
      pdfUrl:""
    }
  },
  created() {
    this.getTaskInfo(this.taskID)
  },
  methods: {
    getTaskInfo(taskID) {
      let _this = this
      fPost("/student/task/getInfo", {
        id: taskID
      }).then(res => {
        if (res.state === "success") {
          _this.previewFile(res.body.sourceId)
        } else {
          $msg(res.errMsg, 2);
        }
      });
    },
    async previewFile(sourceId) {
      fPost("/common/file/preview", {
        sourceId: sourceId
      }).then(res => {
        if (res.state === "success") {
          this.$nextTick(()=> {
            var instance = aliyun.config({
              mount: document.querySelector('#container'),
              url: res.body.previewURL,

            })
            instance.setToken({
              token: res.body.accessToken,
            })
          })
        } else {
          $msg(res.errMsg, 2);
        }
      });
    }
  }
}
</script>
<style>
.pdf {
  top: 0;
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
}
</style>

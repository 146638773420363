<template>
  <div class="sourse-boxs">
    <el-form
      :model="queryParams"
      ref="queryForm"
      label-width="16px"
      size="small"
    >
      <!-- <el-form-item label="作品名称" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入作品名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <!-- <el-form-item label="素材类型" prop="category"> -->
      <!-- <el-select
          v-model="queryParams.type"
          placeholder="请输入素材类型"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        >
          <el-option
            v-for="dict in dict"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        > -->
      <el-form-item style="text-align: right">
        <!-- <span style="float: left">温馨提示：选择的作品素材在保存后生效</span> -->
        <el-button icon="el-icon-refresh" size="small" @click="handleQuery"
          >刷新</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-upload"
          @click="() => (uploadModal = true)"
          >上传</el-button
        >
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" size="mini" :data="sourseList">
      <!-- <el-table-column label="ID" align="center" prop="id" /> -->
      <el-table-column
        show-overflow-tooltip
        label="名称"
        align="center"
        prop="name"
        width="340"
      >
        <template slot-scope="scope">
          <el-link style='color:#f5c319' class='oneline-style' type="text" @click="resetNameHandler(scope.row)">
            {{ scope.row.name }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="类型"
        align="center"
        prop="type"
      />
      <el-table-column
        show-overflow-tooltip
        label="预览"
        align="center"
        prop="url"
        style='padding-top:6px'
      >
       <template slot-scope="scope">
        <div style='padding-top:6px'>
          <img class='hover-style' style="height: 34px;" :src='scope.row.url'  @click="scanHandler(scope.row)"/>
        </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="200"
      >
        <template slot-scope="scope">
          <!-- <el-button type="text" @click="scanHandler(scope.row)">
            预览
          </el-button> -->
          <el-button type="text" @click="resetNameHandler(scope.row)">
            重命名
          </el-button>
          <el-button type="text" @click="$emit('loadToEdit', scope.row)">
            添加到作品中
          </el-button>
          <!-- 禁用删除，防止引用图片失败 -->
          <!-- <el-popconfirm
            @confirm="delSourceHandler(scope.row)"
            title="这是一段内容确定删除吗？"
          >
            <el-button slot="reference" type="text"> 删除 </el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-size="queryParams.pageSize"
      :total="queryParams.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next, jumper"
      style="padding: 10px 20px; text-align: center"
    />

    <el-dialog
      title="文件上传"
      :visible.sync="uploadModal"
      width="800px"
      append-to-body
    >
      <UploadSource
        v-if='uploadModal'
        @getSoureListByUserId="getSoureListByUserId"
        @cancel="uploadModal = false"
      />
    </el-dialog>

    <el-dialog
      title="素材预览"
      :visible.sync="scanModal"
      width="800px"
      append-to-body
    >
      <PreviewSource 
        :scanInfo="scanInfo" 
        v-if='scanModal'
        @cancel="scanModal = false"
      />
    </el-dialog>
    <el-dialog
      title="素材重命名"
      :visible.sync="resetNameModal"
      width="800px"
      append-to-body
    >
      <RenameSource
        v-if="resetNameModal"
        @cancel="resetNameModal = false"
        @renameFile="saveResetName"
        :formInfos="formInfo"
        ref="name"
        :flag='true'
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  listSource,
  addSource,
  editSource,
  delSource,
} from "@/api/works/source";
import PreviewSource from "./preview.vue";
import RenameSource from "./rename.vue";
import UploadSource from "./upload.vue";
export default {
  name: "Source",
  components: { PreviewSource, RenameSource, UploadSource },
  data() {
    return {
      formInfo: {},
      scanModal: false,
      resetNameModal: false,
      scanInfo: {},
      loading: false,
      uploadModal: false,
      dict: [
        { label: "图片", value: "图片" },
        { label: "音频", value: "音频" },
      ],
      queryParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
        pageCount: 0, //总页数
        needCount: true,
      },
      sourseList: [],
      rules: {
        firstName: [
          { required: true, message: "作品名称不可为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getSoureListByUserId();
  },
  methods: {
    getSoureListByUserId() {
      this.loading = true;
      listSource(this.queryParams).then((res) => {
        if (res.state == "success") {
          this.loading = false;
          this.sourseList = res.body.list;
          this.queryParams.total = res.body.total;
          // this.queryParams.currentPage = res.body.pages;
        }
      });
      // this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      // }, 3000);
    },
    //分页
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getSoureListByUserId();
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.getSoureListByUserId();
    },
    // preview
    previewImg(data) {
      this.scanInfo = data;
      this.scanModal = true;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getSoureListByUserId();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
        pageCount: 0, //总页数
        needCount: true,
      };
      this.handleQuery();
    },
    saveResetName(data) {
      editSource({
        id: data.id,
        name: data.firstName + "." + data.lastName,
      }).then((res) => {
        this.resetNameModal = false;
        this.$message.success("修改成功");
        this.getSoureListByUserId();
      });
    },
    handlePreview(file) {
      // console.log(file);
    },
    scanHandler(item) {
      this.scanModal = true;
      this.scanInfo = item;
    },
    resetNameHandler(item) {
      this.resetNameModal = true;
      let names = item.name.split(".");
      this.formInfo = { ...item, firstName: names[0], lastName: names[1] };
    },
    delSourceHandler(item) {
      delSource({
        materialId: item.id,
      }).then((res) => {
        // console.log(res);
        this.$message.success("删除成功");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.upload-sourse .el-upload-list {
  height: 200px !important;
  overflow-y: auto;
}
.el-dialog__body {
  padding-top: 16px !important;
}
.oneline-style {
  word-break:keep-all;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis; 
}
.hover-style{
  border: 2px solid #fff;
}
.hover-style:hover{
  border: 2px solid red;
  cursor: pointer;
}
</style>
<style lang="scss">
.sourse-boxs {
  table {
    border-top: 0 !important;
    border-left: 0 !important;
  }
  
  table td, table th {
    // border-bottom: 0 !important;
    border-right: 0 !important;
    // padding: 3px 5px;
}
}
</style>
<template>
    <el-container class="container_con">
        <el-header class="header">
        </el-header>
        <el-main class="main">
    <el-row class="box-line">
        <el-tabs v-model="activeName" >
            <el-tab-pane label="任务说明" name="acexplain">
                <div class="parent">
                    <span v-html="taskInfo.sourceContent"></span>
                </div>
            </el-tab-pane>
            <el-tab-pane  label="作品上传" name="acupload">
                <el-form v-if="!isUpload" :model="ruleForm" ref="ruleForm" label-width="100px">
                    <el-form-item label="作品名称：" prop="name">
                        <el-input v-model="ruleForm.name" style="width: 70%"></el-input>
                    </el-form-item>
                    <el-form-item label="作品上传：">
                        <div style="display: flex">
                            <el-upload
                                    :auto-upload="false"
                                    :before-remove="beforeRemove"
                                    :on-remove="handleAvatarRemove"
                                    :file-list="fileList"
                                    :on-success="handleAvatarSuccess"
                                    :on-error="handleAvatarFail"
                                    ref="upload"
                                    :on-exceed="handleExceed"
                                    :action="uploadUrl"
                                    :on-change="handleChange"
                                    :limit="1"
                                    :data="myData"
                                    class="upload-demo"
                                    name="file"
                            >
                                <el-button size="small" slot="trigger" type="primary">选取文件</el-button>
                                <el-button
                                        @click="submitUpload()"
                                        size="small"
                                        style="margin-left: 10px;"
                                        type="success"
                                >上传到服务器</el-button>
                                <div class="el-upload__tip" slot="tip">
                                    支持上传
                                    <span>
                                        {{supportList}}
                                    </span>
                                    格式,文件限制范围<span style="margin-left: 10px;">{{rowObject.minSize}}KB</span><span style="margin-left: 5px;">~</span><span style="margin-left: 10px;">{{rowObject.maxSize}}KB</span>
                                </div>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-form>
                <div v-if="isUpload" style="display: flex;flex-direction: column;justify-content: center;width: 100%;align-items: center;margin-top: 30px;">
                    <img src="../../../../assets/images/right.png">
                    <span style="margin-top: 30px;">作品已上传,如需重新上传，请联系老师删除作品后，再上传</span>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-row>
        </el-main>
    </el-container>
</template>

<script>
    export default {
        name: "uploadfile",
        props:{
            taskInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
        },
        data() {
            return {
                uploadUrl: process.env.VUE_APP_API_ADDRESS + "/student/task/uploadTaskFile",
                activeName:"acexplain",                                                                    
                ruleForm: { name: "" },
                fileList: [],
                supportList:[],
                isUpload:false,
                rowObject:{},
            };
        },
        computed: {
            myData() {
                return {
                    taskId: this.taskInfo.id,
                    title: this.ruleForm.name
                };
            }
        },
        created() {
            this.getUploadTaskInfo(this.taskInfo.id)
            this.isUploaded(this.taskInfo.id)
        },
        methods: {
            isUploaded(id) {
                fGet('/student/task/isUploaded',{
                    taskId:id
                }).then(res => {
                    if (res.state === 'success') {
                        this.isUpload = res.body
                    } else {
                        $msg(res.errMsg, 2)
                    }
                }).catch(err => {
                    console.log('err', err)
                    $msg('接口请求失败', 1)
                })
            },
            getUploadTaskInfo(id) {
                fGet('/student/task/getUploadTaskInfo',{
                    taskId:id
                }).then(res => {
                        if (res.state === 'success') {
                            this.supportList = res.body.extList
                            this.rowObject = res.body
                        } else {
                            $msg(res.errMsg, 2)
                        }
                    }).catch(err => {
                    console.log('err', err)
                    $msg('接口请求失败', 1)
                })
            },
            submitUpload() {
                if (this.ruleForm.name === "") {
                    $msg("请填写作品名称后再上传", 2);
                    return false;
                } else if (this.fileList.length === 0) {
                    $msg("请先选择文件", 2);
                    return false;
                } else {
                    this.$refs.upload.submit();
                }
            },

            handleChange(file, fileList) {
                this.fileList = fileList.slice(-1);
            },
            handleAvatarFail(err, res, file) {
                $msg("上传文件失败", 2);
            },
            handleAvatarSuccess(res, file) {
                if (res && res.state == "success") {
                    $msg("上传文件成功", 0);
                    this.isUpload = true
                    this.$emit("setFinishTime")
                } else {
                    $msg( res.errMsg, 2);
                }
                this.$refs.upload.clearFiles();
            },

            handleExceed(files, fileList) {
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${ files.length} 个文件`
                );
            },
            handleAvatarRemove(file, fileList) {
                this.fileList = fileList;
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .container_con {
        width: 100%;
        background: #f4f4f4;
        min-height: 100%;
        height: auto;
        overflow: auto;
    }

    .parent {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .item-btn {
        width: 120px;
        margin-top: 15px;
        margin-bottom: 35px;
        margin-left: 85px;
        color: #ffffff;
        text-align: center;
        font-size: 15px;
    }
    .main {
        width: 1200px;
        margin: 0 auto 0;
        overflow: hidden;
        .back {
            cursor: pointer;
            &:hover {
                color: #f5c319;
            }
            .el-icon-back {
                display: inline-block;
                margin-right: 10px;
            }
        }
        .box-line {
            background: #fff;
            padding: 20px 30px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .title-line {
            font-size: 18px;
            font-weight: 600;
            color: #212121;
        }
        .mt15 {
            span {
                display: inline-block;
                &:nth-child(2) {
                    margin-left: 20px;
                }
            }
        }
    }

    .medal {
        position: absolute;
        left: 0px;
        top: 11px;
    }
</style>
<style>
    p a {
        color: #2ea1f2;
    }
    /* table 样式 */
    table {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
    table td,
    table th {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 3px 5px;
    }
    table th {
        border-bottom: 2px solid #ccc;
        text-align: center;
    }

    /* blockquote 样式 */
    blockquote {
        display: block;
        border-left: 8px solid #d0e5f2;
        padding: 5px 10px;
        margin: 10px 0;
        line-height: 1.4;
        font-size: 100%;
        background-color: #f1f1f1;
    }

    /* code 样式 */
    code {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        background-color: #f1f1f1;
        border-radius: 3px;
        padding: 3px 5px;
        margin: 0 3px;
    }
    pre code {
        display: block;
    }

    /* ul ol 样式 */
    ul, ol {
        margin: 10px 0 10px 20px;
    }
</style>
